import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import Navbar from "../Navbar/Navbar";
import { LoginContainer, LoginLeftBlock, LoginRightBlock } from "../Login/loginStyles";
import { Box, Divider, IconButton, Theme, Typography, useMediaQuery } from "@mui/material";
import LoginPageImage from "../../Assets/Images/login-page-image.png";
import CustomTextField from "../Common/CustomTextField";
import CustomCheckBox from "../Common/CustomCheckBox";
import * as EmailValidator from "email-validator";
import { decodeReferralLink, signUpUser } from "../../Services/userService";
import { resetUserState, selectUser } from "../../Redux/Slices/userSlice";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import CustomButton from "../Common/CustomButton";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";
import GoogleLoginButton from "../Login/GoogleLoginButton";
import FacebookLoginButton from "../Login/FacebookLoginButton";
import { validatePassword } from "../../Utils/utils";
import { getAssignedRepresentative, getProfile } from "../../Services/profileService";
import Cookies from "js-cookie";
import { referralLinkKey } from "../../Utils/tokenKeyValue";
import Asterisk from "../Common/Asterisk";
import MuiPhoneNumber from "material-ui-phone-number";
import VerifyEmailDialog from "./VerifyEmailDialog";
import { Close } from "@mui/icons-material";
import { saveUserSolarInfo } from "../../Services/reportService";
import { useTranslation } from "react-i18next";

interface SignUpData {
	name: string;
	lastName: string;
	email: string;
	phone?: string;
	password: string;
	referralLink?: string;
}

const SignUp = () => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const [data, setData] = useState<SignUpData>({ email: "", password: "", name: "", lastName: "", referralLink: "" });
	const [errors, setErrors] = useState<SignUpData>({ email: "", password: "", name: "", lastName: "" });
	const [alreadyExistError, setAlreadyExistError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [agreeOnTerms, setAgreeOnTerms] = useState<boolean>(false);
	const [linkFound, setLinkFound] = useState<boolean>(false);
	const userSystemInfoString = localStorage.getItem('userReportData'); // Get the string from localStorage
	let userSystemPrice: number | null; // Initialize variable for the system price
	let userSystemSize: number | null; // Initialize variable for the system price

	if (userSystemInfoString) {
		try {
			const userSystemInfo = JSON.parse(userSystemInfoString); // Parse the JSON string
			userSystemPrice = userSystemInfo.systemPrice; // Access the systemPrice property
			userSystemSize = userSystemInfo.systemSizeKW
		} catch (error) {
			console.error('Error parsing userReportData from localStorage:', error);
		}
	}


	useEffect(() => {
		checkReferralLink();
	}, []);

	const checkReferralLink = async () => {
		window.scrollTo(0, 0);
		if (!user.address) {
			navigate(allRoutes.ESTIMATE);
		} else {
			// getting the referral link from cookies
			const referralLink = Cookies.get(referralLinkKey);
			if (referralLink) {
				try {
					const userData = { ...data };
					const { data: decodedLink } = await decodeReferralLink(referralLink);
					console.log("Decoded Link:", decodedLink);
					if (decodedLink?.role === "customer") {
						if (decodedLink.name) userData.name = decodedLink.name;
						if (decodedLink.lastName) userData.lastName = decodedLink.lastName;
						if (decodedLink.email) userData.email = decodedLink.email;
						if (decodedLink.phoneNo) userData.phone = decodedLink.phoneNo;
					}

					setData({ ...userData, referralLink });
					setLinkFound(true);
				} catch (error: any) {
					toast.error(error);
				}
			}
		}
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: name === "password" ? validatePassword(value) : "" }));
		if (name === "email" && alreadyExistError) setAlreadyExistError(false);
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.name = data.name ? "" : t("Signup.firstNameError");
		updatedErrors.lastName = data.lastName ? "" : t("Signup.lastNameError");
		updatedErrors.phone = data.phone ? "" : t("QualificationDialog.phoneError");
		updatedErrors.password = validatePassword(data.password);
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? t("Estimate.invalidAddressMessage")
				: ""
			: t("Login.emailError");

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleSignUpSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			const userData = {
				...data,
				phone_no: data.phone,
				address: user.address ?? "",
				bill: user.bill ?? 0,
				referralLink: data.referralLink,
				systemPrice: userSystemPrice,
				systemSize: userSystemSize

			};
			const newUser: any = await dispatch(signUpUser(userData));
			if (newUser) {
				onSignUpSuccess(newUser);
			} else {
				onSignUpFailure();
			}
		} catch (error: any) {
			if (error === "Error: Email already exists") {
				// setErrors((state) => ({ ...state, email: "Email already exist!" }));
				setAlreadyExistError(true);
			} else if (error === "Error: Incorrect referral link. Please enter a valid link") {
				toast.error(t("Signup.invalidReferralLink"));
				setLinkFound(false);
				removeReferralLink();
			} else {
				toast.error(error);
			}
		}
		setLoading(false);
	};

	const onSignUpSuccess = async (output: any, isSocialLogin?: boolean) => {
		// removing the referral link from cookies
		removeReferralLink();
		if (output?.message?.userId) {
			let userReportData: any = localStorage.getItem("userReportData")
			if (userReportData) {
				userReportData = JSON.parse(userReportData)
			}

			// call API here
			const res = await saveUserSolarInfo({ ...(userReportData || {}), userId: output?.message?.userId })

			// remove specific item from localStorage after sending data
			localStorage.removeItem("userReportData");


			// console.log(res)

		}

		if (output?.message?.errorMessage) {
			toast.warn(output?.message?.errorMessage);
		}

		if (isSocialLogin) {
			toast.success(t("Signup.signupSuccesText"));
			navigate(allRoutes.WHY_CHOOSE_US, { state: { isSocialLogin: true } });
		} else {
			// openVerificationDialog();
			// await dispatch(getProfile());
			// await dispatch(getAssignedRepresentative());
			// navigate(allRoutes.WHY_CHOOSE_US);
			// commenting this for now

			dispatch(resetUserState());
			navigate(allRoutes.LOGIN, { state: { newAccountCreated: true } });
		}
	};

	const onSignUpFailure = () => {
		toast.error(t("Login.loginFailedError"));
	};

	const removeReferralLink = () => {
		const referralLink = Cookies.get(referralLinkKey);
		if (referralLink) {
			Cookies.remove(referralLinkKey);
		}
	};

	const redirectToLogin = () => navigate(allRoutes.LOGIN);

	const unselectReferralLink = () => {
		removeReferralLink();
		setLinkFound(false);
		setData({ ...data, referralLink: undefined });
	};

	return (
		<>
			<Loader open={loading} />
			<Navbar />
			<LoginContainer>
				<LoginLeftBlock>
					<Typography variant={isMobileView ? "h3" : "h2"}>{t("Signup.signupText")}</Typography>
					<Typography mt={10} mb={31}>
						{t("Signup.noCreditCardNeededText")}
					</Typography>

					<GoogleLoginButton
						isSignUpButton
						text="Sign up with Google"
						onSuccess={onSignUpSuccess}
						onFailure={onSignUpFailure}
						referralLink={data.referralLink}
					/>

					<FacebookLoginButton
						isSignUpButton
						text="Sign up with Facebook"
						onSuccess={onSignUpSuccess}
						onFailure={onSignUpFailure}
						referralLink={data.referralLink}
					/>

					<Divider sx={{ my: 32 }}>
						<Typography px={16} color={colors.textMid}>
							{t("Login.orText")}
						</Typography>
					</Divider>

					<form onSubmit={handleSignUpSubmit}>
						<Box display="grid" gridTemplateColumns="1fr 1fr" gap="16px">
							<CustomTextField
								name="name"
								label={
									<>
										{t("Signup.firstNameLabel")}
										<Asterisk />
									</>
								}
								bottom={24}
								value={data.name}
								error={errors.name}
								onChange={handleOnChange}
							/>
							<CustomTextField
								name="lastName"
								label={
									<>
										{t("Signup.lastNameLabel")}
										<Asterisk />
									</>
								}
								bottom={24}
								value={data.lastName}
								error={errors.lastName}
								onChange={handleOnChange}
							/>
						</Box>

						<CustomTextField
							name="email"
							label={
								<>
									{t("Login.emailLabel")}
									<Asterisk />
								</>
							}
							value={data.email}
							error={errors.email}
							onChange={handleOnChange}
						/>
						{alreadyExistError && (
							<Typography mt={3} mx={14} color="error">
								{t("Signup.emailExistsLabel")}{" "}
								<span className="link" onClick={redirectToLogin}>
									{t("Estimate.login")}
								</span>{" "}
								{t("Signup.hereText")}.
							</Typography>
						)}

						<Typography variant="h6" mb={10} mt={24}>
							{t("QualificationDialog.phoneLabel")}
							<Asterisk />
						</Typography>
						<MuiPhoneNumber
							defaultCountry={"us"}
							autoComplete="off"
							onChange={(phoneNumber: any) => {
								setData({ ...data, phone: phoneNumber.toString() });
								if (errors.phone) setErrors({ ...errors, phone: "" });
							}}
							fullWidth
							variant="outlined"
							size="small"
							value={data.phone}
							error={!!errors.phone}
							helperText={errors.phone}
							required
							InputLabelProps={{ shrink: true }}
						/>

						<CustomTextField
							type="password"
							name="password"
							label={
								<>
									{t("Login.passwordLabel")}
									<Asterisk />
								</>
							}
							top={24}
							bottom={17}
							value={data.password}
							error={errors.password}
							onChange={handleOnChange}
						/>
						<CustomTextField
							disabled={linkFound}
							type="text"
							name="referralLink"
							label={t("Signup.referralLinkLabel")}
							top={24}
							bottom={17}
							value={data.referralLink}
							onChange={handleOnChange}
							endIcon={
								data.referralLink ? (
									<IconButton sx={{ p: 2 }} onClick={unselectReferralLink}>
										<Close fontSize="small" />
									</IconButton>
								) : (
									<></>
								)
							}
						/>
						<CustomCheckBox
							text={
								<span>
									{t("Signup.agreeText")} <span className="link">{t("Signup.termsAndConditions")}</span>.
								</span>
							}
							checked={agreeOnTerms}
							onChange={() => setAgreeOnTerms(!agreeOnTerms)}
							bottom={28}
						/>
						<CustomButton type="submit" fullWidth sx={{ mb: 20 }} disabled={!agreeOnTerms}>
							{t("Signup.createAccoutText")}
						</CustomButton>
					</form>

					{!linkFound && (
						<Typography textAlign="center" mt="auto" pt={12}>
							{t("Signup.alreadyHaveAccountText")}{" "}
							<span className="link" onClick={redirectToLogin}>
								{t("Signup.loginText")}
							</span>
						</Typography>
					)}
				</LoginLeftBlock>

				<LoginRightBlock>
					<Box
						className="purple-box"
						sx={{
							backgroundColor: colors.primaryExtraLight,
							"& .login-image": {
								border: `1px solid ${colors.border}`,
							},
						}}
					>
						<img className="login-image" src={LoginPageImage} alt="" />
						<img className="login-image img-2" src={LoginPageImage} alt="" />
						<img className="login-image img-3" src={LoginPageImage} alt="" />
					</Box>
				</LoginRightBlock>
			</LoginContainer>
		</>
	);
};

export default SignUp;
