import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Popover,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box
} from '@mui/material';
import { Language as LanguageIcon } from '@mui/icons-material';
import { US, ES } from 'country-flag-icons/react/3x2';
import { store } from '../../Redux/store';
import { fetchTextSnippets } from '../../Redux/Slices/textSnippetsSlice';
import { useSelector } from '../../Redux/reduxHooks';
import { selectColors } from '../../Redux/Slices/generalSlice';
import CustomButton from '../Common/CustomButton';

const languages = [
    { code: 'en', label: 'English', icon: US },
    { code: 'es', label: 'Español', icon: ES },
];

export const LanguageSelector: React.FC = () => {
    const colors = useSelector(selectColors);
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';
        i18n.changeLanguage(savedLanguage);
        store.dispatch(fetchTextSnippets({ queryParams: { language: savedLanguage } }));
    }, [i18n]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (langCode: string) => {
        i18n.changeLanguage(langCode);
        localStorage.setItem('selectedLanguage', langCode);
        const queryParams = { language: langCode };
        store.dispatch(fetchTextSnippets({ queryParams }));
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'language-popover' : undefined;

    return (
        <Box sx={{ display: "flex", xs: "none", sm: "inline-block" }}>
            <CustomButton
                aria-describedby={id}
                onClick={handleClick}
                variant="outlined"
                startIcon={<LanguageIcon />}
                sx={{

                    color: 'white',

                    padding: "6px 18px", gap: 8

                }}
            >
                {i18n.language.toUpperCase()}
            </CustomButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List>
                    {languages.map((language) => (
                        <ListItem
                            button
                            key={language.code}
                            onClick={() => handleLanguageChange(language.code)}
                        >
                            <ListItemIcon>
                                <language.icon style={{ width: '24px', height: '24px' }} />
                            </ListItemIcon>
                            <ListItemText primary={language.label} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </Box>
    );
};


