import { Box, Button, CircularProgress, IconButton, Theme, Typography, useMediaQuery } from "@mui/material";
import { BorderedBox } from "./whyUsStyles";
import { useEffect, useState } from "react";
import CustomButton from "../Common/CustomButton";
import BillUploader from "./BillUploader";
import { toast } from "react-toastify";
import { scrollToElement } from "../Common/CustomTabs";
import { scanTextFromImage } from "../../Utils/utils";
import { uploadElectricBill } from "../../Services/reportService";
import { BillDataState, saveBill, saveBillDetails } from "../../Redux/Slices/userSlice";
import {
	convertPDFtoJPG,
	getEversourceMonthlyBills,
	getNationalGridMonthlyBills,
	extractValueFromText,
	extractRateValueFromText,
} from "../../Services/ocrService";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import FileUploadLoading from "./FileUploadLoading";
import BillConfirmationDialog from "./BillConfirmationDialog";
import { CancelOutlined } from "@mui/icons-material";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useTranslation } from "react-i18next";

// const dummyNG = `Scanned Text: u u SERVICE FOR BILLING PERIOD PAGE 2 of 2
// National Grid omsum Feb 12, 2016 to Mar 15, 2016
// 1010 ANY STREET
// ANYTOWN RI 99999 ACCOUNTNUNEER
// 99999-99999 Apr 9, 2016 $70.11
// av my ay DETAIL OF CURRENT CHARGES
// To enroll with a supplier or change to Delivery Services
// another supplier, you will need the
// following rE about your account: Service Period No. of days Current Reading - Previous Reading = Total Usage
// Loadzone Rhodelsland Ea sSNA RT ET Tl
// AcctNo: 99999-09999 Cycle: 11, INGE Feb12-Mar15 ~~ 82 ~~ 26757 acwar ~~ 26384actr  373kWh
// METER NUMBER 12345678 NEXT SCHEDULED READ DATE ON OR ABOUT Apr 15
// Electric Usage History RATE Basic Residential Rate A-16
// Month kWh Month kWh -_—  |/0/ 0 0 00 WwW: Vm / 8/48/95
// Mar 15 303 Oct 15 239 Customer Charge 5.00
// ns 20k = LIHEAP Enhancement Charge 0.73
// Jun 15 259 Jan 16 514 Distribution Energy Chg 0.04164 x 373 kWh 15.53
// Jul 15 332 Feb 16 331 CL
// Aug 15 489 Mar 16 373 Energy Efficiency Prgrms 0.01107 x 373 kWh 4.13
// El B52 Renewable Egy Dist Chg 0.00233 x 373 kWh 0.87
// Transmission Charge 0.02348 x 373 kWh 8.76
// Transition Charge -0.00201 x 373 kWh -0.75
// RE Growth Program 0.17
// Total Delivery Services $34.44
// Right To Dispute Your Bill Supply Services
// And To An Impartial Hearing suppLIER National Grid
// If you believe your bill is inaccurate
// or for any reason payment may be
// withheld, you should first contact _EnergyCharge ~~ 008901 x 373kWh ~~ 3321
// our Customer Service Department at Total Supply Services $ 33.21
// 1-800-322-3223. If a mutually
// satisfactory settlement of this matter =
// cannot be made, you have the right Other Charges/Adjustments
// to submit this matter to: Reviewing EE EEE
// Officer, Division of Public Utilities Paperless Billing Credit -0.34
// and Carriers, 89 Jefferson Bivd., .
// Warwick, Rhode Island 02888 _GrossEamings Tax 0.04166667 x 6731 280
// Telephone: 401-780-9700. National Total Other Charges/Adjustments $ 2.46
// Grid will not disconnect your service
// pending proceedings before a
// reviewing officer appointed by the
// Public Utilities Administrator.
// LIHEAP Charge
// This charge is required under Rhode
// Island law and will be used to provide i |
// funding for a Low-Income Home Right To Electric Service: Lermination of Service to Elderly or Handicapped
// Energy Assistance Program —_— ees
// ("LIHEAP") Enhancement Plan, During Serious lliness: If you or anyone presently and If all residents in your household are 62 years of age
// designed to assist low-income electric normally living in your home is seriously ill, we will not or older or if any resident in your household is
// and natural gas households with their discontinue your electric service during such illness handicapped, the Company will not terminate your
// home energy and heating needs. By providing you: have a registered physician certify in writing service for failure to pay the past due bill without
// {aw this charge me not ba iote than to us that such illness exists, the nature and duration of the written approval from the Division of Public Utilities. If
// $10 i lectri illness and you make satisfactory arrangements to pay your you cannot pay your bill all at once, you may be able
// pefyea; prea! Siectic ar bill. This certification must be received within seven (7) days to work out a payment plan with the Company. The
// natural gas service account. from the date that your physician initially contacts our Credit Elderly or Handicapped Forms that must be filled out
// ” as Department at 1-888-211-1313. are available at the Company. The Form also enables
// Explanation of Billing Terms You have a child under twenty four months and a you to participate in "Third Party Notification. If you
// Available financial hardship: If you or anyone presently and normally have any questions or want further information, call the
// - - living in your home has a child under twenty four months old ~~ Credit Department at 1-888-211-1313.
// If you would like an explanation of we will not terminate your electric service, provided you also
// any of the terms used on your bill have a financial hardship. Please call our Credit Department
// you may find them on our web site at 1-888-211-1313 immediately if this applies to you,
// at www.nationalgrid.com or you may Notice About Electronic Check Conversion
// call us at 1-800-322-3223. By sending your completed, signed check to us, you authorize us to use the account information from your check to
// make an electronic fund transfer from your account for the same amount as the check. If the electronic fund transfer
// cannot be processed for technical reasons, you authorize us to process the copy of your check.`;

// const dummyES = `Scanned Text: a
// EVERSSURCE [pI $73.30
// Account Number: by 08/03/20 .
// Statement Date: 07/09/20 Amount Due On 07/04/20 $59.38
// Service Provided To: Last Payment Received On 06/26/20 $59.38
// Balance Forward 8000
// Total Current Charges $73.30
// Electric Usage History - Kilowatt Hours (kwh) Current Charaes for Electricity
// KihDay Supply Delivery
// 4
// ' $36.71 $36.59
// ” Cost of electricity from DIRECT Cost to deliver electricity
// io ENERGY TOWN OF ARLINGTON from Eversource
// N :
// 6
// 4
// 2 $0 $16 $32 $48 $64 $80
// 0
// A Deller Bec lbh Mr dr Uo ano Your electric supplier is
// Average Temperature DIRECT ENERGY TOWN OF ARLINGTON
// P.0. BOX 180
// TN DRECTENERGY COM
// Electric Usage Summary 800-764-0232
// This month your This month you used Billing for TOWN OF ARLINGTON
// average daily 22.7% more
// electric use was than at the
// 8.1 kWh same time last year -
// News For You
// With hurricane season here, it's important to be prepared for a storm. Visit Eversource.com or call 800-592-2000 to report an outage. While online, please make
// sure your contact information is up-to-date and sign-up for outage alerts via text, email or phone call.
// Remit Payment To: Eversource, PO Box 56007, Boston, MA 02205-6007
// EM_200705.TXT
// a Please make your check payable to Eversource or to make your payment today visit Eversource.com.
// EV E RS= U RC E If mailing your payment, please allow up to 5 business days to post to your account.
// Total Amount Due
// Account Number: $
// ccou umbel by 08/03/20 73.30
// Amount Enclosed
// Eversource
// PO Box 56007
// Boston, MA 02205-6007
// 12 0 0000007330 O65 40 2211 1k9
// EVERS=URCE
// —
// oy 0603/20 $73.30
// Account Number: y -
// Customer name key:
// Statement Date: 07/09/20
// Service Provided To: Electric Account Summary
// Amount Due On 07/04/20 $59.38
// Last Payment Received On 06/26/20 -$59.38
// Svc Addr: Saancs Fad as $0.00
// ARLINGTON MA 02476 Uren hargesIbredis
// Rate A1 R1 RESIDENTIAL Cycle 05 Hlgctric Supply Services $367
// Service from 06/05/20 - 07/08/20 33 Days Delivery Services $36.59
// Next read date on or about: Aug 06, 2020 Total CurrentCharges ~~~ $7330
// Meter Current Previous Current Reading Total Amount Due $73.30
// Number Read Read Usage Type
// [ 1821475 | 75600 | 75332 | 288 | YTB Total Charges for Electricity
// monty ikwnuse [amid
// Ster1821475
// di Aug Sep Ot Nov Des Jam goomion Service Charge 268 kWh X 13699 $36.71
// 217 267 203 226 229 323 280 Jee ——— TT
// Subtotal Supplier Services $36.71
// Feb Mar Apr May Jun Jul
// 299 405 288 250 213 268 Delivery
// Rate A1 R1 RESIDENTIAL) (Prorated
// Contact Information te 1821475 a )
// Emergency: 800-592-2000
// WWW.eversource.com Customer Charge $7.00
// CustomerServiceMA@eversource.com Distribution Charge 268 kWh X .06455 $17.30
// Pay by Phone: 888-783-6618 Transition Charge 268 KWh X .00004 $0.01
// Customer Service: 800-592:2000 Transmission Charge 268 KiWh X .02731 $7.32
// Revenue Decoupling Charge 268 kWh X .00090 $0.24
// Important Messages About Your Account Distributed Solar Charge 268 kWh X .00190 $0.51
// DIGGING? STATE LAW REQUIRES YOU OR YOUR CONTRACTOR TO CALL DIG Renewable Energy Charge 268 kWh X .00049 $0.13
// SAFE AT 811 AT LEAST THREE BUSINESS DAYS PRIOR TO DIGGING. FOR Energy Efficiency 268 kWh X .01522 $4.08
// MORE INFORMATION VISIT DIGSAFE.COM. IMPORTANT SAFETY INFORMATION Subtotal Delivery Services $36.59
// IS ALSO AVAILABLE IN THE "SAFETY" SECTION OF EVERSOURCE.COM. Total Cost of Electricity $73.30
// Total Current Charges $73.30
// EM_200705.TXT
// Eversource is required to comply with Department of Public Utilities’ billing and termination regulations. If you have a dispute please see the bill insert for more information.
// Visit the "Monthly Customer Communications" page under "My Account" then "Billing & Payment" on Eversource.com for an electronic version of this insert.
// Eversource offers Payment Plans for customers with overdue bills. Budget Billing is also available to pay a more consistent bill each month.
// Please see the Customer Rights Supplement for more information.`;

interface ElectricBillUploadProps {
	id?: string;
	analysisModuleId?: string;
	billData?: BillDataState | null;
	onUpload?: (bill: BillDataState) => void;
	utilityCompanyName?: string;
}

export interface ScannedDataState {
	delivery: string;
	supply: string;
	total: string;
	monthlyValues: Array<{ month: string; value: string | number; percentage?: number }>;
	// monthlyValues: Array<string | number>;
	jpgImage: any;
	provider: string;
	rate?: string;
}

const ElectricBillUpload = ({
	id,
	analysisModuleId,
	onUpload,
	billData,
	utilityCompanyName,
}: ElectricBillUploadProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const colors = useSelector(selectColors);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const [bill, setBill] = useState<any>("");
	const [uploadedBill, setUploadedBill] = useState<any>("");
	const [startUploading, setStartUploading] = useState<boolean>(false);
	const [scannedData, setScannedData] = useState<ScannedDataState>({
		total: "",
		supply: "",
		delivery: "",
		provider: "",
		jpgImage: "",
		rate: "",
		monthlyValues: [],
	});
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		if (billData?.file && billData?.file !== uploadedBill) {
			setUploadedBill(billData?.file);
		}
	}, [billData?.file]);

	// added this useEffect only for automatically upload bill
	useEffect(() => {
		if (bill) {
			handleBillUploading();
		}
	}, [bill]);

	const handleSelectBill = (pdfFile: any) => {
		setBill(pdfFile);
	};

	const handleBillUploading = async () => {
		if (!bill) return toast.error("Select your bill first!");

		setStartUploading(true);
		try {
			const jpgImages = await convertPDFtoJPG(bill);
			const jpgImage = jpgImages?.[0] ?? "";

			// scanning the uploaded file to fetch the required data
			let scannedText = "";
			for await (const image of jpgImages) {
				scannedText += await scanTextFromImage(image);
			}

			const isCorrectBillUploaded = !!utilityCompanyName
				?.toLowerCase()
				?.split(" ")
				?.filter((item) => "energy" !== item && "electric" !== item)
				?.find((item) => scannedText.toLowerCase().includes(item.toLowerCase()));

			if (!isCorrectBillUploaded) {
				setBill("");
				setStartUploading(false);
				toast.warn(
					`${t("BillUploader.notSupportBillError")} ${utilityCompanyName} bill!`,
				);
				return;
			}
			console.log("Scanned Text:", scannedText);
			console.log("Is correct bill uploaded:", utilityCompanyName, isCorrectBillUploaded);

			let supplyRegex;
			let deliveryRegex;
			let otherChargesRegex;
			let rateCode, rate
			const isEversourceBill = scannedText?.toLowerCase()?.includes("eversource");
			let billsOfMonths = [];

			// extracting the required fields from scanned text
			if (isEversourceBill) {
				supplyRegex = /total supplier services \$([\d.]+)/i;
				deliveryRegex = /total delivery services \$([\d.]+)/i;
				otherChargesRegex = /total current charges \$([\d.]+)/i;
				billsOfMonths = getEversourceMonthlyBills(scannedText);
				rateCode = /Rate\s+([^\r\n]+)/i;
				console.log("Monthly Bills: ", billsOfMonths);
			} else {
				supplyRegex = /total supply services \$\s*([\d.]+)/i;
				deliveryRegex = /total delivery services \$\s*([\d.]+)/i;
				otherChargesRegex = /total other charges\/adjustments \$\s*([\d.]+)/i;
				billsOfMonths = getNationalGridMonthlyBills(scannedText);
				rateCode = /RATE\s+([^\r\n]+)/i;
			}

			const provider = isEversourceBill ? "EVERSOURCE" : "nationalgrid";
			const supply = extractValueFromText(scannedText, supplyRegex);
			const delivery = extractValueFromText(scannedText, deliveryRegex);
			let totalCharges = extractValueFromText(scannedText, otherChargesRegex);
			if (rateCode) {

				rate = extractRateValueFromText(scannedText, rateCode);
			}


			if (!isEversourceBill) {
				// for NationalGrid bill, we get other charges and not totalCharges.
				// so we have to calculate the total charges by taking sum of all the charges
				totalCharges = totalCharges + supply + delivery;
			}

			billsOfMonths = billsOfMonths.filter(
				(item: any) => ![2015, 2016, 2017, 2018, 2020, 2021, 2022, 2023, 2024, 2025].includes(item.value),
			);
			console.log("Bills after deduction: ", billsOfMonths);

			setScannedData({
				delivery: (delivery || "")?.toString(),
				supply: (supply || "")?.toString(),
				total: (totalCharges || "")?.toString(),
				// monthlyValues: billsOfMonths?.map((item) => item.value) || [],
				monthlyValues: billsOfMonths || [],
				provider: provider || "",
				jpgImage: jpgImage,
				rate: (rate || "")?.toString(),
			});
			setOpenDialog(true);

			return;
		} catch (error: any) {
			toast.error(error);
		}
	};

	const handleOnConfirmation = async (data: ScannedDataState) => {
		const { delivery, supply, total, monthlyValues, jpgImage, provider, rate } = data;

		try {
			console.log("Monthly Bills: ", monthlyValues);
			// const sumOfValues = monthlyValues?.reduce((total: number, obj: any) => total + obj.value, 0);
			// const sumOfValues = monthlyValues
			// 	?.filter((item: any) => !!item.value)
			// 	?.map((item: any) => item.value)
			// 	?.reduce((total: number, item: string | number) => total + parseFloat(item?.toString()), 0);

			// const sumOfPercentages = monthlyValues
			// 	?.filter((item: any) => !!item.value)
			// 	?.map((item: any) => item.percentage)
			// 	?.reduce((total: number, item: string | number) => total + parseFloat(item?.toString()), 0);

			// TotalValue = Percentage / PartValue × 100

			// const averageBill = (sumOfValues / sumOfPercentages) * 100;
			// const averageBill = sumOfValues / (monthlyValues?.length || 1);

			// console.log("Sum of values: ", sumOfValues);
			// console.log("Sum of Perce: ", sumOfPercentages);

			console.log("Supply Charges: ", supply);
			console.log("Delivery Charges: ", delivery);
			console.log("Total Charges: ", total);
			// console.log("Average Bill: ", averageBill);

			const monthlyElectricityUsage = {
				January: parseFloat(monthlyValues?.find((item) => item.month === "jan")?.value?.toString() || "0"),
				February: parseFloat(monthlyValues?.find((item) => item.month === "feb")?.value?.toString() || "0"),
				March: parseFloat(monthlyValues?.find((item) => item.month === "mar")?.value?.toString() || "0"),
				April: parseFloat(monthlyValues?.find((item) => item.month === "apr")?.value?.toString() || "0"),
				May: parseFloat(monthlyValues?.find((item) => item.month === "may")?.value?.toString() || "0"),
				June: parseFloat(monthlyValues?.find((item) => item.month === "jun")?.value?.toString() || "0"),
				July: parseFloat(monthlyValues?.find((item) => item.month === "jul")?.value?.toString() || "0"),
				August: parseFloat(monthlyValues?.find((item) => item.month === "aug")?.value?.toString() || "0"),
				September: parseFloat(monthlyValues?.find((item) => item.month === "sep")?.value?.toString() || "0"),
				October: parseFloat(monthlyValues?.find((item) => item.month === "oct")?.value?.toString() || "0"),
				November: parseFloat(monthlyValues?.find((item) => item.month === "nov")?.value?.toString() || "0"),
				December: parseFloat(monthlyValues?.find((item) => item.month === "dec")?.value?.toString() || "0"),
			};

			const formData = new FormData();
			formData.append("Pdf", jpgImage || "");
			formData.append("providerName", provider || "");
			formData.append("deliveryCharges", delivery?.toString());
			formData.append("supplyCharges", supply?.toString());
			// formData.append("averageBill", averageBill?.toString());
			formData.append("uploadedBillCost", total?.toString());
			formData.append("monthlyElectricityUsage", JSON.stringify(monthlyElectricityUsage));
			formData.append("rateCode", rate || "");

			// formData.append("Pdf", bill ?? "");
			// TODO: will upload PDF file in db and display here, for now due to short time I'm uploading the bill as image file

			// storing the bill file in DB
			const { data: response } = await uploadElectricBill(formData);

			setUploadedBill(response?.electricity_bill);

			const uploadedBillData = { provider, supply, delivery, file: response?.electricity_bill, billCost: total };
			dispatch(saveBillDetails(uploadedBillData));
			if (response?.electricity_usage) dispatch(saveBill(response.electricity_usage));
			// if (averageBill) dispatch(saveBill(response.electricity_usage));

			onUpload?.(uploadedBillData);
			setOpenDialog(false);
		} catch (error: any) {
			toast.error(error);
		}
		setStartUploading(false);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setStartUploading(false);
		setBill("");
		setScannedData({
			delivery: "",
			supply: "",
			total: "",
			monthlyValues: [],
			jpgImage: "",
			provider: "",
			rate: "",
		});
	};

	const handleMoveToNextElement = () => {
		scrollToElement(analysisModuleId || "");
	};

	return (
		<Box id={id} className={!uploadedBill ? "buttons-to-hide-in-pdf" : ""} flexDirection="column">
			<Typography variant="h2" mt={{ xs: 24, sm: 32 }} mb={10}>
				{textSnippets["electric_bill_upload_heading"] || "Electric Bill Upload"}
			</Typography>
			<Typography fontSize={16} mb={32} maxWidth={620}>
				{textSnippets["upload_bill_cta"] || "Click to upload or drag & drop you bill here"}
			</Typography>

			<BorderedBox
				sx={{
					paddingBlock: uploadedBill ? 24 : 100,
					position: "relative",
					border: `1px solid ${colors.border}`,
					...(isSmallScreen ? { padding: 0, border: "none" } : {}),
				}}
			>
				{uploadedBill ? (
					<Box display="flex" flexDirection="column" maxWidth={{ xs: "100%", sm: "650px" }} mx="auto">
						<IconButton
							sx={{ position: "absolute", right: 10, top: 10 }}
							onClick={() => {
								setUploadedBill("");
								setBill("");
							}}
						>
							<CancelOutlined />
						</IconButton>
						<img src={uploadedBill} alt="Electric Bill" style={{ maxWidth: "100%", marginInline: "auto" }} />
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: { xs: "100%", sm: 550 },
							marginInline: "auto",
							gap: 24,
							textAlign: "center",
						}}
					>
						<BillUploader imageFile={bill} onUpdate={handleSelectBill} />

						<Button sx={{ py: 5 }} onClick={handleMoveToNextElement}>
							<Typography variant="h6" fontSize={18}>
								{t("BillUploader.skipUpload")}
							</Typography>
						</Button>

						{!!bill && <FileUploadLoading startUploading={startUploading} bill={bill} />}

						<CustomButton fullWidth onClick={handleBillUploading} disabled={startUploading || !bill}>
							{startUploading ? <CircularProgress size={20} color="inherit" /> : t("QualificationDialog.continueText")}
						</CustomButton>
					</Box>
				)}
			</BorderedBox>

			<BillConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				onConfirmation={handleOnConfirmation}
				scannedData={scannedData}
			/>
		</Box>
	);
};

export default ElectricBillUpload;
