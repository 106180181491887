import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PlaceType } from "../../Components/Common/GoogleMapsTextField";

interface NotificationPreferences {
	inApp?: boolean;
	email?: boolean;
	sms?: boolean;
}

interface Discounts {
	lowIncome?: boolean;
	energyCommunity?: boolean;
	americanEquipment?: boolean;
}

export interface BillDataState {
	file?: string;
	delivery?: string | number;
	supply?: string | number;
	provider?: string;
	billCost?: string | number;
}
export interface UserState {
	id?: number | string;
	sequentialId?: number | string;
	name: string;
	lastName: string;
	email?: string;
	password?: string;
	address?: string;
	bill?: number | string;
	addressObject?: PlaceType | any;
	role?: string;
	phone?: string;
	picture?: string;
	selectedPlan?: string;
	billData?: BillDataState | null;
	notificationPreferences?: NotificationPreferences;
	referralLink?: string;
	discounts?: Discounts;
	accessedBy?: any;
	postalCode?: string | null;
	utilityCompanyName?: string;
	systemSize?: number | null;
	systemPrice?: number | null;
}

const initialState: UserState = {
	id: "",
	sequentialId: "",
	name: "",
	lastName: "",
	email: "",
	password: "",
	address: "",
	bill: undefined,
	addressObject: null,
	role: "",
	phone: "",
	picture: "",
	selectedPlan: "",
	billData: null,
	notificationPreferences: {
		inApp: false,
		email: false,
		sms: false,
	},
	discounts: {
		lowIncome: false,
		energyCommunity: false,
		americanEquipment: false,
	},
	accessedBy: null,
	postalCode: null,
	utilityCompanyName: "",
	systemSize: null,
	systemPrice: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState: initialState,
	reducers: {
		saveUserState: (state, action: PayloadAction<UserState>) => {
			state.id = action.payload.id;
			state.sequentialId = action.payload.sequentialId;
			state.name = action.payload.name;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
			state.password = action.payload.password;
			state.address = action.payload.address;
			state.bill = action.payload.bill;
			state.role = action.payload.role;
			state.phone = action.payload.phone;
			state.picture = action.payload.picture;
			state.selectedPlan = action.payload.selectedPlan;
			state.billData = action.payload.billData;
			state.notificationPreferences = action.payload.notificationPreferences;
			state.discounts = action.payload.discounts;
			state.accessedBy = action.payload.accessedBy;
			state.postalCode = action.payload.postalCode;
			state.utilityCompanyName = action.payload.utilityCompanyName;
		},
		saveBill: (state, action: PayloadAction<number | string>) => {
			state.bill = action.payload;
		},
		savePostalCode: (state, action: PayloadAction<string>) => {
			state.postalCode = action.payload;
		},
		saveAddress: (state, action: PayloadAction<string>) => {
			state.address = action.payload;
		},
		saveEmail: (state, action: PayloadAction<string>) => {
			state.email = action.payload;
		},
		saveBillDetails: (state, action: PayloadAction<BillDataState>) => {
			state.billData = action.payload;
		},
		saveAddressObject: (state, action: PayloadAction<PlaceType | null>) => {
			state.addressObject = action.payload;
			state.address = action.payload?.description || "";
		},
		resetUserState: (state) => {
			state.id = initialState.id;
			state.sequentialId = initialState.sequentialId;
			state.name = initialState.name;
			state.lastName = initialState.lastName;
			state.email = initialState.email;
			state.password = initialState.password;
			state.address = initialState.address;
			state.addressObject = initialState.addressObject;
			state.bill = initialState.bill;
			state.role = initialState.role;
			state.phone = initialState.phone;
			state.picture = initialState.picture;
			state.selectedPlan = initialState.selectedPlan;
			state.billData = initialState.billData;
			state.notificationPreferences = initialState.notificationPreferences;
			state.discounts = initialState.discounts;
			state.accessedBy = initialState.accessedBy;
			state.postalCode = initialState.postalCode;
			state.utilityCompanyName = initialState.utilityCompanyName;
		},
	},
});

export const {
	saveUserState,
	saveBillDetails,
	saveEmail,
	saveAddress,
	saveAddressObject,
	saveBill,
	resetUserState,
	savePostalCode,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectBill = (state: RootState) => state.user.bill;
export const selectBillData = (state: RootState) => state.user.bill;
export const selectAddress = (state: RootState) => state.user.address;
export const selectAddressObject = (state: RootState) => state.user.addressObject;

export default userSlice.reducer;
