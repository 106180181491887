import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { BorderedBox } from "./whyUsStyles";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import CustomBarChart from "../Common/Charts/CustomBarChart";
import { useEffect, useState } from "react";
import CustomMenu from "../Common/CustomMenu";
import { formatNumber } from "../../Utils/utils";
import { selectTextSnippetByKey } from "../../Redux/Slices/textSnippetsSlice";
import { RootState } from "../../Redux/store";
import { borderRadius } from "../../Utils/spacings";
import { useSelector } from "../../Redux/reduxHooks";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useTranslation } from "react-i18next";

const defaultChartData = [
	{ value: 0, label: "Jan" },
	{ value: 0, label: "Feb" },
	{ value: 0, label: "Mar" },
	{ value: 0, label: "Apr" },
	{ value: 0, label: "May" },
	{ value: 0, label: "Jun" },
	{ value: 0, label: "Jul" },
	{ value: 0, label: "Aug" },
	{ value: 0, label: "Sep" },
	{ value: 0, label: "Oct" },
	{ value: 0, label: "Nov" },
	{ value: 0, label: "Dec" },
];

const defaultMenuOptions = [{ text: 2023 }];

export interface BillData {
	year: string | number;
	AnnualBill: string | number;
	monthlyBill?: Array<any>;
}

interface UtilityBillIncreasingProps {
	id?: string;
	mt?: string | number;
	sx?: any;
	yearlyBills?: Array<BillData>;
}

const UtilityBillIncreasing = ({ id, mt = 80, sx, yearlyBills = [] }: UtilityBillIncreasingProps) => {
	const { t, i18n } = useTranslation();
	const colors = useSelector(selectColors);

	const [year, setYear] = useState<number | string>("");
	const [data, setData] = useState<Array<any>>(defaultChartData);
	const [options, setOptions] = useState<Array<any>>(defaultMenuOptions);

	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

	// Fetching Text Snippets From Redux
	const houseBillIncreasingHeading = useSelector((state: RootState) =>
		selectTextSnippetByKey(state, "utility_bill_increasing"),
	);

	const houseBillIncreasingDesc = useSelector((state: RootState) =>
		selectTextSnippetByKey(state, "utility_bill_increasing_desc"),
	);
	useEffect(() => {
		if (yearlyBills?.length) {
			setYear(yearlyBills?.[0]?.year);
			const allYears = yearlyBills.map((item) => ({ text: item.year, onClick: () => handleChangeYear(item.year) }));
			setOptions(allYears);
		}
	}, [yearlyBills]);

	useEffect(() => {
		if (year) {
			findDataOfSelectedYear();
		}
	}, [year, yearlyBills]);

	const findDataOfSelectedYear = () => {
		let monthlyBills = yearlyBills.find((item) => item.year === year)?.monthlyBill;
		monthlyBills = monthlyBills?.map((item) => Object.values(item)?.[0]?.toString() || 0);
		const chartMonthlyData = defaultChartData.map((item, idx) => ({ ...item, value: monthlyBills?.[idx] }));
		setData(chartMonthlyData);
	};

	const handleChangeYear = (yearValue: string | number) => {
		setYear(yearValue);
	};

	const maxIndex = data.reduce((maxIndex, currentObj, currentIndex) => {
		const currentValue = parseFloat(currentObj.value);
		const maxValue = parseFloat(data[maxIndex].value);
		return currentValue > maxValue ? currentIndex : maxIndex;
	}, 0);

	const highestBill = formatNumber(data[maxIndex]?.value);

	return (
		<Box id={id} sx={sx}>
			<Typography variant={isSmallScreen ? "h4" : "h2"} mt={mt} mb={10}>
				{houseBillIncreasingHeading || "Your Utility Bill is Increasing"}
			</Typography>
			<Typography fontSize={16} mb={20} maxWidth={620}>
				{houseBillIncreasingDesc ||
					"A utility bill audit is a comprehensive review of an organization's utility invoices to include Electric, Gas, Water/Sewer and Waste invoices in order to track billing errors"}
			</Typography>

			<BorderedBox
				sx={{
					border: `1px solid ${colors.border}`,
					...(isSmallScreen
						? {
							padding: 0,
							borderRadius: borderRadius.md,
							border: `none`,
						}
						: {}),
				}}
			>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="h4" color="primary">
						${highestBill}
					</Typography>

					{!!year && (
						<CustomMenu
							anchorComponent={(props: any) => (
								<Box display="flex" alignItems="center" gap={8} sx={{ cursor: "pointer" }} {...props}>
									<Typography variant="h6">{year}</Typography>
									<KeyboardArrowDownOutlined />
								</Box>
							)}
							options={options}
						/>
					)}
				</Box>
				<Typography fontSize={16} mt={8} mb={38}>
					{t("UtilityBillIncreasing.highestBillText")} {year}
				</Typography>

				<CustomBarChart data={data} />
			</BorderedBox>
		</Box>
	);
};

export default UtilityBillIncreasing;
