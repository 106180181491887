import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomStepper from "../../../Common/CustomStepper";
import {
	ElectricMeterOutlined,
	EventRepeatOutlined,
	HourglassEmptyOutlined,
	HourglassTopOutlined,
	PendingActionsOutlined,
	TaskAltOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../../Routes/AllRoutes";
import InformationBlock from "../../InformationBlock";
import { navbarHeight, tabsHeight } from "../../../../Utils/spacings";
import { useSelector } from "../../../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../../../Redux/Slices/textSnippetsSlice";
import { selectColors } from "../../../../Redux/Slices/generalSlice";
import { useTranslation } from "react-i18next";

const steps = {
	REQUESTED: { text: "Requested", icon: <EventRepeatOutlined />, status: 0 },
	PENDING: { text: "Pending", icon: <HourglassEmptyOutlined />, status: 1 },
	SCHEDULED: { text: "Scheduled", icon: <PendingActionsOutlined />, status: 2 },
	IN_PROGRESS: { text: "In Progress", icon: <HourglassTopOutlined />, status: 3 },
	COMPLETED: { text: "Completed", icon: <TaskAltOutlined />, status: 4 },
};

const MeterReplacement = ({ statusFromAPI, onContinue }: { statusFromAPI?: string; onContinue?: () => void }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const colors = useSelector(selectColors)

	const [status, setStatus] = useState<null | any>(0);
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		const actualStatus =
			Object.values(steps).find((step) => step.text === statusFromAPI)?.status || steps.REQUESTED.status;
		setStatus(actualStatus);
	}, [statusFromAPI]);

	return (
		<>
			<InformationBlock
				icon={{
					iconComponent: ElectricMeterOutlined,
					iconBg: colors.primary + "95",
					iconColor: "white",
					iconSize: 60,
					iconBorderColor: colors.primary + "45",
				}}
				title={
					<>
						{t("MeterReplacement.mainHeadText")}{" "}
						<Typography
							variant="inherit"
							component="span"
							color={status === steps.COMPLETED.status ? "success.dark" : "primary.main"}
						>
							{status === steps.REQUESTED.status
								? "requested"
								: status === steps.PENDING.status
									? "pending"
									: status === steps.SCHEDULED.status
										? "scheduled"
										: status === steps.IN_PROGRESS.status
											? "in progress"
											: "completed"}
						</Typography>
					</>
				}
				subtitle={
					status === steps.COMPLETED.status
						? t("MeterReplacement.stepCompleteText")
						: t("MeterReplacement.stepIncompleteText")
				}
				buttons={
					status === steps.COMPLETED.status
						? [
							{
								buttonText: (textSnippets['meter_replacement_download_certificate'] || "Download Certificate"),
								buttonOnClick: () => {
									// will download certificate here
								},
							},
							{
								buttonText: (textSnippets['meter_replacement_continue_button'] || "Continue"),
								variant: "outlined",
								buttonOnClick: () => onContinue?.(),
								// buttonOnClick: () => navigate(allRoutes.ACTIVATION),
							},
						]
						: [
							{
								buttonText: (textSnippets['meter_replacement_home_button'] || "Back to home"),
								buttonOnClick: () => navigate(allRoutes.WHY_CHOOSE_US),
								// buttonOnClick: () => navigate(allRoutes.PROPOSAL_ACCEPTANCE),
							},
						]
				}
				height={`calc(100vh - ${navbarHeight}px - ${tabsHeight}px  - 185px - 105px)`} // here (185px = height of CustomStepper) and (105px = height of CustomToggleButton)
			/>

			<Box pt={42} maxWidth={640} mx="auto" mb={{ xs: 32, sm: 70 }}>
				<CustomStepper steps={Object.values(steps)} activeStep={status} />
			</Box>
		</>
	);
};
export default MeterReplacement;
