import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomStepper from "../../Common/CustomStepper";
import { EventRepeatOutlined, HourglassTopOutlined, TaskAltOutlined } from "@mui/icons-material";
import SchedulingPageLayout from "../SchedulingPageLayout";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../Routes/AllRoutes";
import InformationBlock from "../InformationBlock";
import { navbarHeight, tabsHeight } from "../../../Utils/spacings";
import ViewDesign from "./ViewDesign";
import { selectColors } from "../../../Redux/Slices/generalSlice";
import { toast } from "react-toastify";
import { getCADDesignStatus } from "../../../Services/cadDesignService";
import { useSelector } from "../../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../../Redux/Slices/textSnippetsSlice";
import { useTranslation } from "react-i18next";


const steps = {
	REQUESTED: { text: "Requested", icon: <EventRepeatOutlined />, status: 0 },
	IN_PROGRESS: { text: "In progress", icon: <HourglassTopOutlined />, status: 1 },
	COMPLETED: { text: "Completed", icon: <TaskAltOutlined />, status: 2 },
};

const CADDesign = ({ onContinue }: { onContinue?: () => void }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const colors = useSelector(selectColors);

	const [imageUrl, setImageUrl] = useState<string>("");
	const [status, setStatus] = useState<null | any>(steps.REQUESTED.status);
	const [viewDesign, setViewDesign] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const { data } = await getCADDesignStatus();

			const actualStatus =
				Object.values(steps).find((step) => step.text === data?.status)?.status || steps.REQUESTED.status;
			setStatus(actualStatus);
			setImageUrl(data?.image || "");
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	return (
		<SchedulingPageLayout loading={loading}>
			{viewDesign ? (
				<ViewDesign image={imageUrl} />
			) : (
				<>
					<InformationBlock
						icon={{ iconBg: status === steps.COMPLETED.status ? colors.successLight : colors.primary }}
						title={
							<>
								{textSnippets["cad_design_heading"] || "Your CAD design is"}{" "}
								<Typography
									variant="inherit"
									component="span"
									color={status === steps.COMPLETED.status ? "success.dark" : "primary.main"}
								>
									{status === steps.REQUESTED.status
										? "requested"
										: status === steps.IN_PROGRESS.status
											? "in progress"
											: "complete"}
								</Typography>
							</>
						}
						subtitle={
							status !== steps.COMPLETED.status &&
							t("CadDesign.designCompleteText")
						}
						buttons={
							status === steps.COMPLETED.status
								? [
									{
										buttonText: textSnippets["cad_design_view_button"] || "View Design",
										buttonOnClick: () => setViewDesign(true),
									},
									{
										buttonText: textSnippets["cad_design_continue_button"] || "Continue",
										variant: "outlined",
										buttonOnClick: () => onContinue?.(),
										// buttonOnClick: () => navigate(allRoutes.CONTRACT_SIGNING),
									},
								]
								: [
									{
										buttonText: textSnippets["cad_back_to_home_button"] || "Back To Home",
										buttonOnClick: () => navigate(allRoutes.WHY_CHOOSE_US),
									},
								]
							// : [{ buttonText: "Back To Home", buttonOnClick: () => navigate(allRoutes.PROPOSAL_ACCEPTANCE) }]
						}
						height={`calc(100vh - ${navbarHeight}px - ${tabsHeight}px  - 185px)`}
					/>

					<Box pt={42} maxWidth={640} mx="auto" mb={{ xs: 32, sm: 70 }}>
						<CustomStepper steps={Object.values(steps)} activeStep={status} />
					</Box>
				</>
			)}
		</SchedulingPageLayout>
	);
};

export default CADDesign;
