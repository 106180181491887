import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import { SiteSurveyLeftBlock } from "../schedulingStyles";
import { selectColors } from "../../../Redux/Slices/generalSlice";
import { useDispatch, useSelector } from "../../../Redux/reduxHooks";
import { selectUser } from "../../../Redux/Slices/userSlice";
import { useEffect, useState } from "react";
import CustomCalendar from "../../Common/CustomCalendar";
import CustomStepper from "../../Common/CustomStepper";
import {
	EventRepeatOutlined,
	HourglassTopOutlined,
	PendingActionsOutlined,
	TaskAltOutlined,
	// TextSnippet,
} from "@mui/icons-material";
import SchedulingPageLayout from "../SchedulingPageLayout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allRoutes } from "../../../Routes/AllRoutes";
import InformationBlock from "../InformationBlock";
import moment from "moment";
import Loader from "../../Common/Loader";
import { navbarHeight, tabsHeight } from "../../../Utils/spacings";
import {
	getAvailableSlotsOfInstaller,
	getInstallationStatus,
	scheduleInstallation,
} from "../../../Services/installationService";
import { selectAllTextSnippets } from "../../../Redux/Slices/textSnippetsSlice";
import { useTranslation } from "react-i18next";

export const dummyInstallerPicture =
	"https://static.vecteezy.com/system/resources/thumbnails/008/013/844/small_2x/asian-technician-civil-engineer-use-tablet-with-smart-pen-technology-on-transport-site-construction-to-inspect-blueprint-engineering-work-online-with-team-at-sunset-time-photo.jpg";

const steps = {
	TO_BE_SCHEDULED: { text: "To be scheduled", icon: <EventRepeatOutlined />, status: 0 },
	SCHEDULED: { text: "Scheduled", icon: <PendingActionsOutlined />, status: 1 },
	IN_PROGRESS: { text: "In progress", icon: <HourglassTopOutlined />, status: 2 },
	COMPLETED: { text: "Completed", icon: <TaskAltOutlined />, status: 3 },
};

const getFormattedDate = (date: any) => moment(date).format("YYYY-MM-DD");

const Installation = ({ onCancel }: { onCancel?: () => void }) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);

	const [installer, setInstaller] = useState({ _id: "", name: "", picture: "" });
	const [date, setDate] = useState<any>(new Date());
	const [timeSlot, setTimeSlot] = useState<string>("");
	const [allSlots, setAllSlots] = useState<Array<string>>([]);
	// const [saving, setSaving] = useState<boolean>(false);
	const [status, setStatus] = useState<null | any>(0);
	const [installation, setInstallation] = useState<null | any>(null);
	const [displayErrorOverlay, setDisplayErrorOverlay] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [fetchingSlots, setFetchingSlots] = useState<boolean>(false);

	useEffect(() => {
		getBookedAppointment();
	}, []);
	useEffect(() => {
		getAvailableSlots();
	}, [date]);

	const getBookedAppointment = async () => {
		setLoading(true);
		try {
			const { data: bookedAppointment } = await getInstallationStatus();
			console.log("Installation: ", bookedAppointment);
			if (bookedAppointment) {
				setInstallation(bookedAppointment);

				const actualStatus =
					Object.values(steps).find((step) => step.text === bookedAppointment?.status)?.status ||
					steps.TO_BE_SCHEDULED.status;
				setStatus(actualStatus);
			}
		} catch (error: any) {
			toast.error(error);
		}
		// try {
		// 	const { data: bookedAppointment } = await getInstallationStatus();
		// 	console.log("Installation: ", bookedAppointment);
		// 	if (bookedAppointment) {
		// 		setInstallation(bookedAppointment);

		// 		const actualStatus =
		// 			Object.values(steps).find((step) => step.text === bookedAppointment?.status)?.status ||
		// 			steps.TO_BE_SCHEDULED.status;
		// 		setStatus(actualStatus);
		// 	} else {
		// 		getInstallerDetails();
		// 	}
		// } catch (error: any) {
		// 	if (error === "No installation request found!") {
		// 		getInstallerDetails();
		// 	} else {
		// 		toast.error(error);
		// 	}
		// }
		setLoading(false);
	};

	// const getInstallerDetails = async () => {
	// 	try {
	// 		const { data } = await assignInstaller();

	// 		const assignedInstaller = data?.installerData?.installerId ?? null;
	// 		if (assignedInstaller) {
	// 			if (!assignedInstaller?.ImageUrl) {
	// 				assignedInstaller.picture = dummyInstallerPicture;
	// 			} else {
	// 				assignedInstaller.picture = assignedInstaller?.ImageUrl;
	// 			}

	// 			setInstaller(assignedInstaller);
	// 			setDisplayErrorOverlay(false);

	// 			const repData = {
	// 				id: assignedInstaller?._id,
	// 				name: assignedInstaller?.name,
	// 				email: assignedInstaller?.email,
	// 				phone: assignedInstaller?.phone_no,
	// 				image: assignedInstaller?.ImageUrl,
	// 			};
	// 			dispatch(saveRepresentativeState(repData));
	// 		} else {
	// 			setDisplayErrorOverlay(true);
	// 		}
	// 	} catch (error: any) {
	// 		toast.error(error);
	// 	}
	// };

	const getAvailableSlots = async () => {
		// if (!installer?._id) return;

		setFetchingSlots(true);
		try {
			setTimeSlot("");
			const formattedDate = getFormattedDate(date);
			const { data: availableSlots } = await getAvailableSlotsOfInstaller(formattedDate);
			// const { data: availableSlots } = await getAvailableSlotsOfInstaller(installer._id, formattedDate);

			setAllSlots(availableSlots);
			// setTimeSlot(availableSlots?.[0] ?? "");
		} catch (error: any) {
			toast.error(error);
		}
		setFetchingSlots(false);
	};

	const handleBookInstallation = async () => {
		setLoading(true);
		// setSaving(true);
		try {
			const formattedDate = getFormattedDate(date);
			const { data: scheduledInstallation } = await scheduleInstallation(formattedDate, timeSlot);
			console.log("Booked: ", scheduledInstallation);

			setInstallation(scheduledInstallation);
			setStatus(steps.SCHEDULED.status);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
		// setSaving(false);
	};

	const handleCancel = async () => {
		try {
			onCancel?.();
			// navigate(allRoutes.PERMIT_INTERCONNECTION);
		} catch (error: any) {
			toast.error(error);
		}
	};

	// Two steps for installation screen
	// get all the pre-filled slots of installer from google calendar
	// fill the slot on installer's calendar,

	// update the api of assign site technician. and write the logic of assigning the technician with least filled slots

	return (
		<SchedulingPageLayout>
			<Loader open={loading} />

			<Box position="relative">
				{displayErrorOverlay && (
					<Box
						sx={{
							position: "absolute",
							width: "calc(100% + 30px)",
							height: "calc(100% + 30px)",
							zIndex: 3,
							left: -15,
							top: -15,
							WebkitBackdropFilter: "blur(7px)",
							backdropFilter: "blur(7px)",
							backgroundColor: "rgba(255, 255, 255, 0.5)",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							gap: "24px",
						}}
					>
						<Typography variant="h3">{t("Installation.installerNotAssignedText")}</Typography>
						<CustomButton sx={{ minWidth: 300, marginBlock: 10 }} onClick={() => navigate(allRoutes.WHY_CHOOSE_US)}>
							{t("SiteSurvey.backHomeText")}
						</CustomButton>
					</Box>
				)}

				{status !== steps.TO_BE_SCHEDULED.status ? (
					<InformationBlock
						icon={{ iconBg: status === steps.COMPLETED.status ? "success.dark" : "primary.main" }}
						title="You have successfully scheduled installation"
						subtitle={
							<>
								{t("Installation.installationTakePlaceText")}{" "}<b>{moment(installation?.date).format("MM/DD/yyyy")}</b>, at{" "}
								<b>{installation?.time}</b>.
							</>
						}
						buttons={[
							{
								buttonText: status === steps.COMPLETED.status ? t("QualificationDialog.continueText") : t("SiteSurvey.backHomeText"),
								buttonOnClick: () =>
									navigate(status === steps.COMPLETED.status ? allRoutes.TOWN_INSPECTION : allRoutes.WHY_CHOOSE_US),
							},
						]}
						height={`calc(100vh - ${navbarHeight}px - ${tabsHeight}px  - 185px)`}
					/>
				) : (
					<Box sx={{ py: 48, pt: { xs: 0, sm: 32 } }}>
						<Typography variant="h4">
							{textSnippets["installer_panel_schedule_heading"] || "Schedule Installation"}
						</Typography>
						<Typography fontSize={16} mt={8}>
							{textSnippets["installer_panel_proceed_subtitle"] || "Please proceed further to installation"}
						</Typography>
						<Divider sx={{ mt: 14, mb: 32 }} />

						<Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1.5fr" }, gap: 88 }}>
							<SiteSurveyLeftBlock sx={{ backgroundColor: colors.lightGray }}>
								{/* <Box display="flex" alignItems="center" gap={16}>
									<Avatar
										sx={{ width: 83, height: 83, border: `1px solid ${colors.border}` }}
										src={installer.picture}
									/>
									<Box>
										<Typography variant="h4">{installer.name || "Your Installer"}</Typography>
										<Typography fontSize={16} mt={8}>
											Solar Panel Installer
										</Typography>
									</Box>
								</Box>
								<Divider /> */}
								<Box>
									<Typography variant="h5">
										{textSnippets["installer_panel_location_label"] || "Your location"}
									</Typography>
									<Typography mt={8}>{user.address || "Your address"}</Typography>
								</Box>

								<Box>
									<Typography variant="h5">
										{textSnippets["installer_panel_survey_team_label"] || "Survey Team"}
									</Typography>
									<Typography mt={8}>
										{textSnippets["installer_panel_survey_text"] ||
											"Survey Team organized from time to time in accordance with paragraph 2 of Schedule 2."}
									</Typography>
								</Box>

								<Box>
									<Typography variant="h5">{textSnippets["installer_panel_service_label"] || "Our service"}</Typography>
									<Typography mt={8}>
										{textSnippets["installer_panel_service_text"] ||
											"Solar panels are usually made from silicon, or another semiconductor material installed in a metal panel frame with a glass casing."}
									</Typography>
								</Box>
							</SiteSurveyLeftBlock>

							<Box
								sx={{
									display: "grid",
									borderBottom: `1px solid ${colors.border}`,
									gridTemplateColumns: { xs: "1fr", sm: "1fr 0.8fr" },
									gap: 40,
								}}
							>
								<Box>
									<Typography variant="h5" mb={24}>
										{textSnippets["select_datetime_label"] || "Select Date & Time"}
									</Typography>
									<CustomCalendar value={date} onChange={setDate} />
								</Box>
								{allSlots?.length && !fetchingSlots ? (
									<Box display="flex" flexDirection="column" gap={16} mb={24}>
										{allSlots?.map((item, key) => (
											<CustomButton
												key={key}
												variant="outlined"
												color={item === timeSlot ? "primary" : "secondary"}
												onClick={() => setTimeSlot(item)}
												sx={{ paddingBlock: 9 }}
											>
												{item}
											</CustomButton>
										))}
									</Box>
								) : (
									<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={10}>
										{fetchingSlots ? (
											<Typography textAlign="center" color={colors.textMid} display="flex" alignItems="center" gap={10}>
												<CircularProgress color="inherit" size="12px" />
												{t("SiteSurvey.fetchingSlotText")}
											</Typography>
										) : (
											<Typography textAlign="center" color={colors.textMid}>
												{t("SiteSurvey.noSlotText")}
											</Typography>
										)}
									</Box>
								)}
							</Box>
						</Box>

						<Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1.5fr" }, gap: 80, mt: 24 }}>
							<Box display={{ xs: "none", sm: "inline-block" }} />
							<Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }} gap={17}>
								<CustomButton
									onClick={handleCancel}
									sx={{ backgroundColor: colors.lightGray, color: colors.text, "&:hover": { color: "white" } }}
								>
									{t("AccountSettings.cancelText")}
								</CustomButton>
								<CustomButton
									// disabled={!timeSlot || saving}
									disabled={!timeSlot}
									onClick={handleBookInstallation}
									sx={{ order: { xs: -1, sm: 1 } }}
								>
									{t("Installation.scheduleInstallationBtnText")}
								</CustomButton>
							</Box>
						</Box>
					</Box>
				)}

				<Box pt={42} maxWidth={640} mx="auto" mb={{ xs: 32, sm: 70 }}>
					<CustomStepper steps={Object.values(steps)} activeStep={status} />
				</Box>
			</Box>
		</SchedulingPageLayout>
	);
};

export default Installation;
