import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import { SiteSurveyLeftBlock } from "../schedulingStyles";
import { selectColors } from "../../../Redux/Slices/generalSlice";
import { useDispatch, useSelector } from "../../../Redux/reduxHooks";
import { selectUser } from "../../../Redux/Slices/userSlice";
import { useEffect, useState } from "react";
import CustomCalendar from "../../Common/CustomCalendar";
import CustomStepper from "../../Common/CustomStepper";
import {
	EventRepeatOutlined,
	HourglassTopOutlined,
	PendingActionsOutlined,
	TaskAltOutlined,
} from "@mui/icons-material";
import SchedulingPageLayout from "../SchedulingPageLayout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allRoutes } from "../../../Routes/AllRoutes";
import InformationBlock from "../InformationBlock";
import {
	// assignTechnician,
	bookSiteSurvey,
	getAppointmentDetails,
	getAvailableSlotsOfTechnician,
	// updatePaymentStatus,
} from "../../../Services/schedulingService";
import moment from "moment";
import Loader from "../../Common/Loader";
import { navbarHeight, tabsHeight } from "../../../Utils/spacings";
import { selectAllTextSnippets } from "../../../Redux/Slices/textSnippetsSlice";
import { getAssignedRepresentative } from "../../../Services/profileService";
import { useTranslation } from "react-i18next";

// const dummyTechnicianPicture =
// 	"https://static.vecteezy.com/system/resources/thumbnails/008/013/844/small_2x/asian-technician-civil-engineer-use-tablet-with-smart-pen-technology-on-transport-site-construction-to-inspect-blueprint-engineering-work-online-with-team-at-sunset-time-photo.jpg";

export const siteSurveySteps = {
	TO_BE_SCHEDULE: { text: "To be scheduled", icon: <EventRepeatOutlined />, status: 0 },
	SCHEDULED: { text: "Scheduled", icon: <PendingActionsOutlined />, status: 1 },
	IN_PROGRESS: { text: "In progress", icon: <HourglassTopOutlined />, status: 2 },
	COMPLETED: { text: "Completed", icon: <TaskAltOutlined />, status: 3 },
};

const getFormattedDate = (date: any) => moment(date).format("YYYY-MM-DD");

const SiteSurvey = ({ onContinue }: { onContinue?: () => void }) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	// const [searchParams, setSearchParams] = useSearchParams();

	// const [technician, setTechnician] = useState({ _id: "", name: "", picture: "" });
	const [date, setDate] = useState<any>(new Date());
	const [timeSlot, setTimeSlot] = useState<string>("");
	const [allSlots, setAllSlots] = useState<Array<string>>([]);
	const [saving, setSaving] = useState<boolean>(false);
	const [status, setStatus] = useState<null | any>(0);
	const [appointment, setAppointment] = useState<null | any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [fetchingSlots, setFetchingSlots] = useState<boolean>(false);
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		getBookedAppointment();
	}, []);
	useEffect(() => {
		getAvailableSlots();
	}, [date]);

	const getBookedAppointment = async () => {
		setLoading(true);
		try {
			const { data: bookedAppointment } = await getAppointmentDetails();
			console.log("Appointment: ", bookedAppointment);
			setAppointment(bookedAppointment);

			if (bookedAppointment) {
				if (siteSurveySteps.TO_BE_SCHEDULE.text === bookedAppointment?.status) {
					// getTechnicianDetails();
					setStatus(siteSurveySteps.TO_BE_SCHEDULE.status);
				}
				if (siteSurveySteps.SCHEDULED.text === bookedAppointment?.status) {
					setStatus(siteSurveySteps.SCHEDULED.status);

					// if (!bookedAppointment?.isPaymentDeposited) {
					// 	const isRedirectedFromPaymentPage = !!searchParams.get("payment_intent");
					// 	if (isRedirectedFromPaymentPage) {
					// 		await updatePaymentStatus(bookedAppointment?._id);

					// 		searchParams.delete("payment_intent");
					// 		searchParams.delete("redirect_status");
					// 		searchParams.delete("payment_intent_client_secret");
					// 		setSearchParams(searchParams);
					// 	} else {
					// 		navigate(allRoutes.PAYMENT, { state: { appointmentId: bookedAppointment?._id } });
					// 	}
					// }
				} else if ([siteSurveySteps.IN_PROGRESS.text].includes(bookedAppointment?.status)) {
					setStatus(siteSurveySteps.IN_PROGRESS.status);
				} else if ([siteSurveySteps.COMPLETED.text].includes(bookedAppointment?.status)) {
					setStatus(siteSurveySteps.COMPLETED.status);
				}
			}
			//  else {
			// 	getTechnicianDetails();
			// }
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	// const getTechnicianDetails = async () => {
	// 	try {
	// 		// const { data } = await assignTechnician();
	// 		// const assignedTechnician = data?.technicianData?.technicianId ?? {};
	// 		// if (!assignedTechnician.ImageUrl) {
	// 		// 	assignedTechnician.picture = dummyTechnicianPicture;
	// 		// }
	// 		// setTechnician(assignedTechnician);
	// 	} catch (error: any) {
	// 		toast.error(error);
	// 	}
	// };

	const getAvailableSlots = async () => {
		// if (!technician?._id) return;

		setFetchingSlots(true);
		try {
			setTimeSlot("");
			const formattedDate = getFormattedDate(date);
			const { data: availableSlots } = await getAvailableSlotsOfTechnician(formattedDate);

			setAllSlots(availableSlots);
		} catch (error: any) {
			toast.error(error);
		}
		setFetchingSlots(false);
	};

	const handleBookSurvey = async () => {
		setSaving(true);
		try {
			const formattedDate = getFormattedDate(date);
			const { data: bookedSurvey } = await bookSiteSurvey(formattedDate, timeSlot);

			console.log("Booked: ", bookedSurvey);
			setAppointment(bookedSurvey);
			setStatus(siteSurveySteps.SCHEDULED.status);

			await dispatch(getAssignedRepresentative());
			// navigate(allRoutes.PAYMENT, { state: { appointmentId: bookedSurvey?._id } });
		} catch (error: any) {
			toast.error(error);
		}
		setSaving(false);
	};

	const handleCancel = async () => {
		try {
			navigate(allRoutes.WHY_CHOOSE_US);
			// navigate(allRoutes.PROPOSAL_ACCEPTANCE);
		} catch (error: any) {
			toast.error(error);
		}
	};

	return (
		<SchedulingPageLayout>
			<Loader open={loading} />
			{status > 0 ? (
				<InformationBlock
					title={
						status === siteSurveySteps.SCHEDULED.status ? (
							textSnippets["survey_scheduled_heading"] || "You have successfully scheduled a survey"
						) : (
							<span>
								{textSnippets["site_survey_sub_section_heading"] || "Your site survey is"}{" "}
								<Typography
									variant="inherit"
									component="span"
									color={status === siteSurveySteps.COMPLETED.status ? "success.dark" : "primary.main"}
								>
									{status === siteSurveySteps.IN_PROGRESS.status ? "in progress" : "complete"}
								</Typography>
							</span>
						)
					}
					subtitle={
						status === siteSurveySteps.IN_PROGRESS.status ? (
							<>{t("SiteSurvey.surveyMsg")}</>
						) : status === siteSurveySteps.COMPLETED.status ? (
							<>{t("SiteSurvey.surveyText2")}</>
						) : (
							<>
								{t("SiteSurvey.surveyText3")} <b>{moment(appointment?.date).format("MM/DD/yyyy")}</b>, at{" "}
								<b>{appointment?.time}</b>.
							</>
						)
					}
					buttons={[
						{
							buttonText: status === siteSurveySteps.COMPLETED.status ? t("QualificationDialog.continueText") : t("SiteSurvey.backHomeText"),
							buttonOnClick:
								status === siteSurveySteps.COMPLETED.status
									? () => onContinue?.()
									: () => navigate(allRoutes.WHY_CHOOSE_US),
						},
					]}
					height={`calc(100vh - ${navbarHeight}px - ${tabsHeight}px  - 185px)`}
				/>
			) : (
				<Box sx={{ py: 48, pt: { xs: 0, sm: 32 } }}>
					<Typography variant="h4">{textSnippets["site_survey_heading"] || "Site Survey"}</Typography>
					<Typography fontSize={16} mt={8}>
						{textSnippets["site_survey_subheading"] || "Schedule and manage your site survey below"}
					</Typography>
					<Divider sx={{ mt: 14, mb: 32 }} />

					<Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1.5fr" }, gap: 80 }}>
						<SiteSurveyLeftBlock sx={{ backgroundColor: colors.lightGray }}>
							{/* <Box display="flex" alignItems="center" gap={16}>
								<Avatar sx={{ width: 83, height: 83, border: `1px solid ${colors.border}` }} src={technician.picture} />
								<Box>
									<Typography variant="h4">{technician.name || "Your Technician"}</Typography>
									<Typography fontSize={16} mt={8}>
										Site Technician
									</Typography>
								</Box>
							</Box> */}
							{/* <Divider /> */}
							<Box>
								<Typography variant="h5">{textSnippets["location_label"] || "Your location"}</Typography>
								<Typography mt={8}>{user.address || "Your address"}</Typography>
							</Box>

							{/* <Box mt={21}>
								<Typography variant="h5">
									{textSnippets["message_from_label"] || "A message from"} {technician.name?.split(" ")?.[0]}
								</Typography>
								<Typography mt={8}>
									{textSnippets["scheduling_thank_you"] ||
										" Thank you for scheduling a site survey. I looks forward to meeting you."}
								</Typography>
							</Box> */}

							<Box mt={21}>
								<Typography variant="h5">{textSnippets["what_to_expect_label"] || "What to expect"} </Typography>
								<Typography mt={8}>
									{textSnippets["access_requirements"] ||
										"	We’ll need access to the roof, attic and electrical panel. Please Ensure an adult is home at the time of the visit. Allow a 1 hour window for arrival. If anything changes, you may reschedule your site survey in advance."}
								</Typography>
							</Box>
						</SiteSurveyLeftBlock>

						<Box
							sx={{
								display: "grid",
								borderBottom: `1px solid ${colors.border}`,
								gridTemplateColumns: { xs: "1fr", sm: "1fr 0.8fr" },
								gap: 40,
							}}
						>
							<Box>
								<Typography variant="h5" mb={24}>
									{textSnippets["select_datetime_label"] || "Select Date & Time"}
								</Typography>
								<CustomCalendar value={date} onChange={setDate} />
							</Box>
							{allSlots?.length && !fetchingSlots ? (
								<Box display="flex" flexDirection="column" gap={16} mb={24}>
									{allSlots?.map((item, key) => (
										<CustomButton
											key={key}
											variant="outlined"
											color={item === timeSlot ? "primary" : "secondary"}
											onClick={() => setTimeSlot(item)}
											sx={{ paddingBlock: 9 }}
										>
											{item}
										</CustomButton>
									))}
								</Box>
							) : (
								<Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
									{fetchingSlots ? (
										<Typography textAlign="center" color={colors.textMid} display="flex" alignItems="center" gap={10}>
											<CircularProgress color="inherit" size="12px" />
											{t("SiteSurvey.fetchingSlotText")}
										</Typography>
									) : (
										<Typography textAlign="center" color={colors.textMid}>
											{t("SiteSurvey.noSlotText")}
										</Typography>
									)}
								</Box>
							)}
						</Box>
					</Box>

					<Box sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", sm: "1fr 1.5fr" }, gap: 80, mt: 24 }}>
						<Box display={{ xs: "none", sm: "inline-block" }} />
						<Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr" }} gap={17}>
							<CustomButton
								onClick={handleCancel}
								sx={{ backgroundColor: colors.lightGray, color: colors.text, "&:hover": { color: "white" } }}
							>
								{t("AccountSettings.cancelText")}
							</CustomButton>
							<CustomButton disabled={!timeSlot || saving} onClick={handleBookSurvey} sx={{ order: { xs: -1, sm: 1 } }}>
								{t("SiteSurvey.bookSurveyText")}
							</CustomButton>
						</Box>
					</Box>
				</Box>
			)}

			<Box pt={42} maxWidth={640} mx="auto" mb={{ xs: 32, sm: 70 }}>
				<CustomStepper steps={Object.values(siteSurveySteps)} activeStep={status} />
			</Box>
		</SchedulingPageLayout>
	);
};

export default SiteSurvey;
