import { ReactFacebookLoginInfo } from "react-facebook-login";
import FacebookLogin, { RenderProps } from "react-facebook-login/dist/facebook-login-render-props";
import CustomButton from "../Common/CustomButton";
import { FacebookIcon } from "../../Utils/icons";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { loginUser, signUpUser } from "../../Services/userService";
import { toast } from "react-toastify";
import { selectUser } from "../../Redux/Slices/userSlice";
import { getAssignedRepresentative, getProfile } from "../../Services/profileService";
import { useTranslation } from "react-i18next";

interface FacebookLoginButtonProps {
	text?: string;
	isSignUpButton?: boolean;
	onSuccess?: (output: any, isGoogleSignup: boolean) => void;
	onFailure?: () => void;
	referralLink?: string;
}

const FacebookLoginButton = ({
	text = "Login with Facebook",
	isSignUpButton,
	onFailure,
	onSuccess,
	referralLink,
}: FacebookLoginButtonProps) => {
	const { t, i18n } = useTranslation();
	text = t('SocialLoginButtons.text2');
	const dispatch = useDispatch();
	const userState = useSelector(selectUser);
	const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID ?? "";

	// add HTTPS=true in start script of package.json for testing Facebook Login

	const handleLoginSuccess = async (response: ReactFacebookLoginInfo) => {
		console.log("Facebook Login");
		console.table(response);
		const name: string = response.name || "";
		const parts: string[] = name.split(" ");

		const firstName: string = parts.slice(0, -1).join(" ");
		const lastName: string = parts[parts.length - 1];

		try {
			const userData = {
				id: response.id,
				name: firstName ?? "",
				lastName: lastName ?? "",
				email: response.email,
				password: "",
				address: userState.address,
				bill: userState.bill,
				addressObject: userState.addressObject,
				role: "",
				picture: response.picture?.data?.url,
				referralLink
			};

			let output = {};
			if (isSignUpButton) {
				output = await dispatch(signUpUser(userData, true));
				await dispatch(getProfile());
				await dispatch(getAssignedRepresentative());
			} else {
				const user = await dispatch(loginUser({ email: userData.email ?? "", password: "" }));
				if (user.access_token === "An Email sent to your account please verify") {
					return toast.error(
						t("SocialLoginButtons.toastMsg"),
					);
				}
			}

			onSuccess?.(output, true);
		} catch (error: any) {
			console.error(error);
			toast.error("Something went wrong!");
		}
	};

	const handleLoginFail = async (error: any) => {
		console.error(error);
		if (error === "User already exists with this email") {
			toast.error(t("SocialLoginButtons.emailExists"));
		} else {
			onFailure?.();
		}
	};

	return (
		<div>
			<FacebookLogin
				appId={facebookAppId}
				autoLoad={false}
				fields="name,email,picture"
				callback={handleLoginSuccess}
				onFailure={handleLoginFail}
				render={(props: RenderProps) => (
					<CustomButton
						variant="outlined"
						color="secondary"
						startIcon={<FacebookIcon height={20} width={20} />}
						fullWidth
						style={{ marginTop: 16 }}
						disabled={props.isDisabled}
						onClick={props.onClick}
					>
						{text}
					</CustomButton>
				)}
			/>
		</div>
	);
};

export default FacebookLoginButton;
