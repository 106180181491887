import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { TextSnippet } from "../../Utils/types";
import { getAllTextSnippets } from "../../Services/textSnippetsService";
import { RootState } from "../store";
import { dashboards } from "../../Utils/enums";

interface TextSnippetState {
	textSnippets: TextSnippet[];
	loading: boolean;
	error: string | null;
}

// export const fetchTextSnippets = createAsyncThunk<TextSnippet[], void, { rejectValue: string }>(
//   'textSnippets/fetchTextSnippets',
//   async (_, { rejectWithValue }) => {
//     try {
//       const {data} = await getAllTextSnippets(dashboards.FRONTEND_CUSTOMER_APP);
//       if (!data) throw new Error('Network response was not ok');
//       return data as TextSnippet[];
//     } catch (error) {
//       if (error instanceof Error) {
//         return rejectWithValue(error.message);
//       }
//       return rejectWithValue('Unexpected error');
//     }
//   }
// );

export const fetchTextSnippets = createAsyncThunk<
	TextSnippet[],
	{ queryParams?: Record<string, any> } | void, // Allow no arguments by making the parameter optional
	{ rejectValue: string }
>("textSnippets/fetchTextSnippets", async (arg = {}, { rejectWithValue }) => {
	// Default to an empty object if no arguments are provided
	const queryParams = arg?.queryParams || {};
	try {
		// Convert queryParams to a query string
		const queryString = new URLSearchParams(queryParams).toString();

		// Pass the query string to the API call
		const { data } = await getAllTextSnippets(dashboards.FRONTEND_CUSTOMER_APP, queryString);

		if (!data) throw new Error("Network response was not ok");
		return data as TextSnippet[];
	} catch (error) {
		if (error instanceof Error) {
			return rejectWithValue(error.message);
		}
		return rejectWithValue("Unexpected error");
	}
});

const initialState: TextSnippetState = {
	textSnippets: [],
	loading: false,
	error: null,
};

const textSnippetSlice = createSlice({
	name: "textSnippets",
	initialState,
	reducers: {
		// Reducer logic here (if needed)
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTextSnippets.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchTextSnippets.fulfilled, (state, action: PayloadAction<TextSnippet[]>) => {
				state.textSnippets = action.payload;
				state.loading = false;
			})
			.addCase(fetchTextSnippets.rejected, (state, action: PayloadAction<string | undefined>) => {
				state.loading = false;
				state.error = action.payload || "An error occurred";
			});
	},
});

export default textSnippetSlice.reducer;

export const selectTextSnippetByKey = (state: RootState, key: string): string => {
	return state.textSnippets.textSnippets.find((snippet: TextSnippet) => snippet.key === key)?.value || "";
};

export const selectAllTextSnippets = (state: RootState): { [key: string]: string } => {
	return state.textSnippets.textSnippets.reduce(
		(acc, snippet) => {
			acc[snippet.key] = snippet.value;
			return acc;
		},
		{} as { [key: string]: string },
	);
};
