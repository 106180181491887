import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		lng: "en",
		fallbackLng: "en",
		resources: {
			en: {
				translation: {
					Estimate: {
						homeAddress: "Home Address",
						monthlyElectricityBill: "Monthly Electricity Bill",
						notOperatingMessage: "We are sorry, but we are currently not operating in your area.",
						invalidAddressMessage: "Enter a valid address!",
						freeSolarQuote: "Free Solar Quote",
						enterHomeDetails:
							"Please enter your home details for an instant solar quote. Make sure the pin is on your home.",
						useCurrentLocation: "Use my current location",
						buttonTextNext: "Next",
						existingUser: "Existing User?",
						login: "Login",
						youreInGoodHands: "You’re in good hands with",
						referralLinkSavedMessage:
							"Great news! Your referral link has been successfully saved – thank you for choosing to join us! Please finish the sign-up process to complete your registration.",
					},
					Login: {
						passwordError: "Password cannot be empty",
						validEmailError: "Enter a valid email",
						emailError: "Email cannot be empty",
						verifyEmail:
							"An email has been sent to your account. Please check your inbox and verify your email address.",
						loginFailedError: "Something went wrong!",
						signInToYourAccount: "Sign in to your account",
						orText: "Or",
						emailLabel: "Email Address",
						passwordLabel: "Password",
						forgotPasswordText: "Forgot your password?",
						signInButtonText: "Sign In",
						noAccountText: "Don't have an account?",
						signUpButtonText: "Sign Up",
						rememberMeText: "Remember me",
						loginSuccessToast: "Successfully signed in!",
					},
					Signup: {
						firstNameError: "First Name cannot be empty",
						lastNameError: "Last Name cannot be empty",
						referralLinkError: "Incorrect referral link. Please enter a valid link",
						signupSuccesText: "Successfully signed up!",
						signupText: "Create new Account",
						noCreditCardNeededText: "Free, no credit card needed.",
						firstNameLabel: "First Name",
						lastNameLabel: "Last Name",
						referralLinkLabel: "Referral Link",
						emailExistsLabel: "A user with that email already exists. Please",
						hereText: "here",
						termsAndConditions: "terms and conditions",
						agreeText: "I agree to the user",
						createAccoutText: "Create Account",
						alreadyHaveAccountText: "Already have an account?",
						loginText: "Log in",
					},
					VerifyEmailDialog: {
						verifyEmailHeading: "Verify your Email",
						verifyEmailDesciption:
							"An email has been sent to the email address you provided. Please check your inbox and verify your email to proceed",
						verifyEmailDesciption2: "Tip: Please check your spam folder in case the email was mistakenly filtered",
						buttonTextClose: "Close",
					},
					SocialLoginButtons: {
						text1: "Sign in with Google",
						text2: "Sign in with Facebook",
						emailExists: "Email already in use. Please try logging in.",
						toastMsg: "An email has been sent to your account. Please check your inbox and verify your email address.",
					},
					ForgotPasswordDialog: {
						toastError: "User with given email doesn't exist",
						toastSuccess: "Password reset link has been sent!",
						errorMsg: "Email address not found in our records",
						checkYourEmail: "Check your email",
						forgotPassword: "Forgot Password?",
						sentResetLink: "We've sent you a password reset link",
						noWorries: "No worries we’ll send you reset instructions",
						openEmailApp: "Open email app",
						sendResetLinkText: "Send now",
						didntReceiveEmail: "Didn’t receive the email?",
						rememberPassword: "Remember your password?",
						clickToResend: "Click to resend",
						signIn: "Sign in",
					},
					SolarReport: {
						solarProductionText: "Solar Production",
						utilityUsageText: "Utility Usage",
						comprehensiveServiceOffering: "We handle design, permits, installation",
						monitoringMaintenanceRepairs: "25-year monitoring, maintenance, and repairs",
						energyGuaranteeText: "Energy Guarantee",
						noOutOfPocketCostText: "No out-of-pocket cost for all system components if qualified",
						residualBillText: "Residual Bill",
						solarLoanText: "Solar Loan",
						switchToSolarText: "Switch to Solar",
						stayWithElectricityText: "Stay with electric",
						year25CostOfDoingNothing: "25 year cost of doing nothing",
					},
					QualificationDialog: {
						nameError: "Name cannot be empty",
						phoneError: "Phone number cannot be empty",
						addressError: "Address cannot be empty",
						toastSuccess: "Thank you for submitting the contact information.",
						existingUserEmailError: "User with this email already exists",
						existingUserPhoneError: "Record with this phone number already exists",
						unauthorizedPersonnel:
							"We cannot provide quotes to unauthorized personnel. Please complete the form so the authorized owner can pick up where you left off.",
						noInstantProposal:
							"Our instant proposal is not available for your property type at this time. Please leave your contact details and a representative will follow up with you shortly",
						thankYouForDetails: "Thank you for providing the contact details",
						propertyType: "What is your property type?",
						propertyOwner: "Are you the owner or the authorized person?",
						continueText: "Continue",
						propertyTypeHome: "Home",
						propertyTypeBusiness: "Business",
						propertyTypeNonProfit: "Non-Profit",
						ownershipYes: "Yes",
						ownershipNo: "No",
						phoneLabel: "Phone Number",
						submitLabel: "Submit",
						linkCopied: "Link copied to clipboard",
						linkToForm: "Link to form",
						backToHomePage: "Back to home page",
					},
					UtilityBillIncreasing: {
						highestBillText: "Highest Bill",
					},
					DashedBoxWithValues: {
						avoid: "Avoid",
						perMonth: "Per Month",
						excessChargesText: "in excess charges",
					},
					GoogleConfirmationDialog: {
						accountCreatedtext: "User account created",
						accountCreatedSubtext:
							"Please double-check the user information in your account settings to make sure that it is up-to-date. This information is essential for your contract!",
					},
					SelectPlan: {
						selectPlanText: "Select Plan",
					},
					AcceptProposalDialog: {
						nameError: "Name cannot be empty",
						nameMismatchError: "Name didn't matched",
						acceptProposalText: "Accept Proposal",
						acceptProposalSubText: "Please enter your name to accept",
						acceptProposalSubText2: "the proposal & schedule a site survey",
						namePlaceholder: "Enter your name",
						acceptProposalButtonText: "Accept Proposal",
					},
					Navbar: {
						referFriendText: "Refer a Friend",
						updateStatusText: "Change Status",
						viewLogsText: "View Logs",
						viewDocumentsText: "View Documents",
					},
					ReferFriendDialog: {
						referralLinkSuccessToast: "Referral link has been sent!",
						emailAlreadyExistError: "User with given email already have an account",
						referFriendDescription: "Enter the email of your friend to send him referral link",
						referFriendLinkBtnText: "Send Referral Link",
					},
					Sidebar: {
						RepInfo: "Representative Information",
						updateInfoText: "Update Information",
						logoutText: "Log Out",
					},
					AccountSettings: {
						addressFieldText: "Address cannot be empty",
						billError1: "Electricity bill must be greater than 0",
						billError2: "Electricity bill cannot be empty",
						profileUpdateSuccessToast: "Profile updated successfully!",
						existingEmailError: "A user with this email already exists",
						settingText: "Account Settings",
						settingSubText: "Update your photo and personal detaills",
						yourPhotoText: "Your Photo",
						yourPhotoSubText: "This will be displayed on your profile",
						addressText: "Address",
						billText: "Electricity Bill",
						currentPasswordText: "Current Password",
						newPasswordText: "New Password",
						cancelText: "Cancel",
						saveChangesText: "Save Changes",
					},
					OtpVerifyDialog: {
						otpLengthError: "Please enter complete otp code",
						otpEmptyError: "Otp cannot be empty",
						emailVerified: "Your email has been verified!",
						otpError: "Please enter the correct OTP code",
						verifyEmailSubheading: "Please check your inbox for a verification code to update your email",
						verifyButtonText: "Verify",
					},
					SiteSurvey: {
						surveyMsg: "You will be informed about the results as soon as the site survey is finished.",
						surveyText2: "Please continue to the next step",
						surveyText3: "The site survey will take place on",
						backHomeText: "Back To Home",
						fetchingSlotText: "Fetching slots",
						noSlotText: "No slots available for selected date",
						bookSurveyText: "Book Survey",
					},
					CadDesign: {
						designCompleteText: "You will be notified as soon as the design has completed. Please check again later.",
					},
					MeterReplacement: {
						stepCompleteText: "Hay your meter replacement is completed you can download certificate.",
						stepIncompleteText: "You will be notified as soon as the design has completed. Please check again later.",
						mainHeadText: "Meter replacement is",
					},
					Activation: {
						activationComplete: "Congratulations! Your solar panels are ready to shine!",
					},
					ContractSigning: {
						contractSigningText: "Your contract signing is",
						contractSigningCompleteText: "Your signed contract is",
					},
					Installation: {
						installerNotAssignedText: "Installer is not assigned yet!",
						installationTakePlaceText: "Installation will take place on",
						scheduleInstallationBtnText: "Schedule Installation",
					},
					VerifyEmail: {
						invalidLinkError: "Invalid or expired link!",
						emailVerified: "Email verified successfully!",
						resentVerificationEmailSent: "Verification link resent on your email!",
						loadingText1: "Verifying your email address!",
						emailVerifySuccessText: "Great! Your email has been verified!",
						allSetGoText: "You're all set to go!",
					},
					BillAnalysis: {
						billAnalysisText: "Analyzing your bill...",
						billAnalysisText2: "Your analysis will be available after you upload your electric bill",
					},
					BillConfirmationDialog: {
						emptyFieldError: "This field cannot be empty",
						totalSupplyError:
							"Total charges cannot be equal to supply charges. Please check if the information you entered is correct",
						totalDeliveryError:
							"Total charges cannot be equal to delivery charges. Please check if the information you entered is correct",
						totalError: "Total charges cannot be less than the sum of the supply and delivery charges",
						monthlyValuesError: "Please fill at least apr fields",
						confirmText: "Confirm",
						confirmBillDetails: `Hello there! We've scanned your utility bill to grab the total, delivery, and supply costs, along with monthly
				energy consumption. Our tool did its best, but sometimes it misses details. Please check the values below, fill
				in any missing info or fix errors. Total, delivery, and supply costs are a must, and a bit on monthly energy
				helps too. Your input ensures accurate results. Thanks for helping us fine-tune your energy data!`,
					},
					BillUploader: {
						fileSizeError: "The selected image exceeds the maximum allowed size. Please choose a smaller image file.",
						fileSizeErrorPdf:
							"The dropped PDF file exceeds the maximum allowed size. Please choose a smaller PDF file.",
						fileTypeError: "Please drop a PDF file.",
						uploadBillText: "Please Upload Your Electric Bill",
						uploadBillSubText: "Drag & Drop Or Upload PDF File Here",
						notSupportBillError: "The document you uploaded does not seem to be a valid bill. Please update a valid",
						skipUpload: "I don't have my bill now and will provide it latter",
					},
					ChatWidget: {
						emailLabel: "Email",
						nameLabel: "Name",
						startChatText: "Start Chat",
					},
					YourSolar: {
						requestDesignText: "Request Design",
						successTextDesgin:
							"Your request has been successfully sent! It may take up to 7 days to complete your designs.",
					},

					Footer: {
						selectPlanText: "You may now select a plan",
						viewProjectText: "View Project",
						skipPlanText: "Skip to plan selections",
					},
				},
			},
			es: {
				translation: {
					Estimate: {
						homeAddress: "Dirección de casa",
						monthlyElectricityBill: "Factura mensual de electricidad",
						notOperatingMessage: "Lo sentimos, pero actualmente no estamos operando en su área.",
						invalidAddressMessage: "¡Ingrese una dirección válida!",
						freeSolarQuote: "Cotización Solar Gratis",
						enterHomeDetails:
							"Por favor, ingrese los detalles de su hogar para obtener una cotización solar instantánea. Asegúrese de que el pin esté ubicado en su hogar.",
						useCurrentLocation: "Usar mi ubicación actual",
						buttonTextNext: "Siguiente",
						existingUser: "¿Usuario existente?",
						login: "Acceso",
						youreInGoodHands: "Estás en buenas manos con",
						referralLinkSavedMessage:
							"¡Buenas noticias! Su enlace de referencia se ha guardado correctamente. ¡Gracias por elegir unirse a nosotros! Finalice el proceso de registro para completar su registro.",
					},
					Login: {
						passwordError: "La contraseña no puede estar vacía",
						validEmailError: "Ingrese un correo electrónico válido",
						emailError: "El correo electrónico no puede estar vacío",
						verifyEmail:
							"Se ha enviado un correo electrónico a su cuenta. Por favor, revise su bandeja de entrada y verifique su dirección de correo electrónico.",
						loginFailedError: "¡Algo salió mal!",
						signInToYourAccount: "Inicie sesión en su cuenta",
						orText: "o",
						emailLabel: "Dirección de correo electrónico",
						passwordLabel: "Contraseña",
						forgotPasswordText: "¿Olvidó su contraseña?",
						signInButtonText: "Iniciar sesión",
						noAccountText: "¿No tiene una cuenta?",
						signUpButtonText: "Registrarse",
						rememberMeText: "Recuérdame",
						loginSuccessToast: "¡Inicio de sesión exitoso!",
					},
					Signup: {
						firstNameError: "El Nombre de pila no puede estar vacío",
						lastNameError: "El apellido no puede estar vacío",
						referralLinkError: "Enlace de referencia incorrecto. Por favor, ingrese un enlace válido",
						signupSuccesText: "¡Registro exitoso!",
						signupText: "Crear nueva cuenta",
						noCreditCardNeededText: "Gratis, no se necesita tarjeta de crédito.",
						firstNameLabel: "Nombre de pila",
						lastNameLabel: "Apellido",
						referralLinkLabel: "Enlace de referencia",
						emailExistsLabel: "Ya existe un usuario con ese correo electrónico. Por favor",
						hereText: "aquí",
						termsAndConditions: "términos y condiciones",
						agreeText: "Acepto los",
						createAccoutText: "Crear cuenta",
						alreadyHaveAccountText: "¿Ya tienes una cuenta?",
						loginText: "Iniciar sesión",
					},
					VerifyEmailDialog: {
						verifyEmailHeading: "Verifique su correo electrónico",
						verifyEmailDesciption:
							"Se ha enviado un correo electrónico a la dirección de correo electrónico que proporcionó. Por favor revise su bandeja de entrada y verifique su correo electrónico para continuar.",
						verifyEmailDesciption2:
							"Consejo: Por favor, revise su carpeta de spam en caso de que el correo haya sido filtrado por error",
						buttonTextClose: "Cerrar",
					},
					SocialLoginButtons: {
						text1: "Iniciar sesión con Google",
						text2: "Iniciar sesión con Facebook",
						emailExists: "El correo electrónico ya está en uso. Por favor, intente iniciar sesión.",
						toastMsg:
							"Se ha enviado un correo electrónico a su cuenta. Por favor, revise su bandeja de entrada y verifique su dirección de correo electrónico.",
					},
					ForgotPasswordDialog: {
						toastError: "El usuario con el correo electrónico proporcionado no existe",
						toastSuccess: "¡El enlace para restablecer la contraseña ha sido enviado!",
						errorMsg: "La dirección de correo electrónico no se encuentra en nuestros registros",
						checkYourEmail: "Revise su correo electrónico",
						forgotPassword: "¿Olvidó su contraseña?",
						sentResetLink: "Le hemos enviado un enlace para restablecer su contraseña",
						noWorries: "No se preocupe, le enviaremos instrucciones para restablecerla",
						openEmailApp: "Abrir aplicación de correo",
						sendResetLinkText: "Enviar ahora",
						didntReceiveEmail: "¿No recibió el correo?",
						rememberPassword: "¿Recuerda su contraseña?",
						clickToResend: "Haga clic para reenviar",
						signIn: "Iniciar sesión",
					},
					SolarReport: {
						solarProductionText: "Producción Solar",
						utilityUsageText: "Uso de Utilidades",
						comprehensiveServiceOffering: "Nos encargamos del diseño, permisos, instalación",
						monitoringMaintenanceRepairs: "25 años de monitoreo, mantenimiento y reparaciones",
						energyGuaranteeText: "Garantía de Energía",
						noOutOfPocketCostText: "Sin costo de bolsillo para todos los componentes del sistema si califica",
						residualBillText: "Factura Residual",
						solarLoanText: "Préstamo Solar",
						switchToSolarText: "Cambia a Solar",
						stayWithElectricityText: "Mantenerse con electricidad",
						year25CostOfDoingNothing: "Costo de 25 años por no hacer nada",
					},
					QualificationDialog: {
						nameError: "El nombre no puede estar vacío",
						phoneError: "El número de teléfono no puede estar vacío",
						addressError: "La dirección no puede estar vacía",
						toastSuccess: "Gracias por enviar la información de contacto.",
						existingUserEmailError: "Ya existe un usuario con este correo electrónico",
						existingUserPhoneError: "Ya existe un registro con este número de teléfono",
						unauthorizedPersonnel:
							"No podemos proporcionar cotizaciones a personal no autorizado. Por favor, complete el formulario para que el propietario autorizado pueda continuar donde usted lo dejó.",
						noInstantProposal:
							"Nuestra propuesta instantánea no está disponible para el tipo de propiedad en este momento. Por favor, deje sus datos de contacto y un representante se comunicará con usted en breve.",
						thankYouForDetails: "Gracias por proporcionar los datos de contacto",
						propertyType: "¿Cuál es el tipo de su propiedad?",
						propertyOwner: "¿Es usted el propietario o la persona autorizada?",
						continueText: "Continuar",
						propertyTypeHome: "Hogar",
						propertyTypeBusiness: "Negocio",
						propertyTypeNonProfit: "Sin Fines de Lucro",
						ownershipYes: "Sí",
						ownershipNo: "No",
						phoneLabel: "Número de Teléfono",
						submitLabel: "Enviar",
						linkCopied: "Enlace copiado al portapapeles",
						linkToForm: "Enlace al formulario",
						backToHomePage: "Volver a la página de inicio",
					},
					UtilityBillIncreasing: {
						highestBillText: "Factura Más Alta",
					},
					DashedBoxWithValues: {
						avoid: "Evitar",
						perMonth: "Por Mes",
						excessChargesText: "en cargos adicionales",
					},
					GoogleConfirmationDialog: {
						accountCreatedtext: "Cuenta de usuario creada",
						accountCreatedSubtext:
							"Por favor, revise la información de usuario en la configuración de su cuenta para asegurarse de que esté actualizada. ¡Esta información es esencial para su contrato!",
					},
					SelectPlan: {
						selectPlanText: "Seleccionar plan",
					},
					AcceptProposalDialog: {
						nameError: "El nombre no puede estar vacío",
						nameMismatchError: "El nombre no coincide",
						acceptProposalText: "Aceptar propuesta",
						acceptProposalSubText: "Por favor, introduce tu nombre para aceptar",
						acceptProposalSubText2: "la propuesta y programar una visita al sitio",
						namePlaceholder: "Introduce tu nombre",
						acceptProposalButtonText: "Aceptar propuesta",
					},

					Navbar: {
						referFriendText: "Recomendar a un amigo",
						updateStatusText: "Cambiar estado",
						viewLogsText: "Ver registros",
						viewDocumentsText: "Ver documentos",
					},
					ReferFriendDialog: {
						referralLinkSuccessToast: "¡El enlace de referencia ha sido enviado!",
						emailAlreadyExistError: "El usuario con el correo electrónico proporcionado ya tiene una cuenta",
						referFriendDescription: "Introduce el correo electrónico de tu amigo para enviarle un enlace de referencia",
						referFriendLinkBtnText: "Enviar enlace de referencia",
					},
					Sidebar: {
						RepInfo: "Información del representante",
						updateInfoText: "Actualizar información",
						logoutText: "Cerrar sesión",
					},
					AccountSettings: {
						addressFieldText: "La dirección no puede estar vacía",
						billError1: "La factura de electricidad debe ser mayor a 0",
						billError2: "La factura de electricidad no puede estar vacía",
						profileUpdateSuccessToast: "¡Perfil actualizado con éxito!",
						existingEmailError: "Ya existe un usuario con este correo electrónico",
						settingText: "Configuración de la cuenta",
						settingSubText: "Actualiza tu foto y detalles personales",
						yourPhotoText: "Tu foto",
						yourPhotoSubText: "Esto se mostrará en tu perfil",
						addressText: "Dirección",
						billText: "Factura de electricidad",
						currentPasswordText: "Contraseña actual",
						newPasswordText: "Nueva contraseña",
						cancelText: "Cancelar",
						saveChangesText: "Guardar cambios",
					},
					OtpVerifyDialog: {
						otpLengthError: "Por favor, introduce el código completo de OTP",
						otpEmptyError: "El OTP no puede estar vacío",
						emailVerified: "¡Tu correo electrónico ha sido verificado!",
						otpError: "Por favor, introduce el código OTP correcto",
						verifyEmailSubheading:
							"Por favor, revisa tu bandeja de entrada para obtener un código de verificación y actualizar tu correo electrónico",
						verifyButtonText: "Verificar",
					},
					SiteSurvey: {
						surveyMsg: "Se le informará sobre los resultados tan pronto como finalice la inspección del sitio.",
						surveyText2: "Por favor, continúe con el siguiente paso",
						surveyText3: "La inspección del sitio tendrá lugar el",
						backHomeText: "Volver al inicio",
						fetchingSlotText: "Obteniendo horarios",
						noSlotText: "No hay horarios disponibles para la fecha seleccionada",
						bookSurveyText: "Reservar inspección",
					},
					CadDesign: {
						designCompleteText:
							"Se le notificará tan pronto como el diseño esté completado. Por favor, vuelva a comprobar más tarde.",
					},
					MeterReplacement: {
						stepCompleteText: "¡Listo! Su reemplazo de medidor se ha completado. Puede descargar el certificado.",
						stepIncompleteText:
							"Se le notificará tan pronto como el diseño esté completado. Por favor, vuelva a comprobar más tarde.",
						mainHeadText: "El reemplazo del medidor está",
					},
					Activation: {
						activationComplete: "¡Felicidades! ¡Tus paneles solares están listos para brillar!",
					},
					ContractSigning: {
						contractSigningText: "La firma de su contrato está",
						contractSigningCompleteText: "Su contrato firmado está",
					},
					Installation: {
						installerNotAssignedText: "¡Aún no se ha asignado un instalador!",
						installationTakePlaceText: "La instalación tendrá lugar el",
						scheduleInstallationBtnText: "Programar instalación",
					},
					VerifyEmail: {
						invalidLinkError: "¡Enlace no válido o caducado!",
						emailVerified: "¡Correo electrónico verificado con éxito!",
						resentVerificationEmailSent: "¡El enlace de verificación se ha reenviado a su correo electrónico!",
						loadingText1: "¡Verificando su dirección de correo electrónico!",
						emailVerifySuccessText: "¡Genial! ¡Tu correo electrónico ha sido verificado!",
						allSetGoText: "¡Todo listo para empezar!",
					},
					BillAnalysis: {
						billAnalysisText: "Analizando su factura...",
						billAnalysisText2: "Su análisis estará disponible después de cargar su factura eléctrica",
					},
					BillConfirmationDialog: {
						emptyFieldError: "Este campo no puede estar vacío",
						totalSupplyError:
							"Los cargos totales no pueden ser iguales a los cargos de suministro. Por favor, verifique si la información que ingresó es correcta",
						totalDeliveryError:
							"Los cargos totales no pueden ser iguales a los cargos de entrega. Por favor, verifique si la información que ingresó es correcta",
						totalError: "Los cargos totales no pueden ser menores que la suma de los cargos de suministro y entrega",
						monthlyValuesError: "Por favor, complete al menos los campos de abril",
						confirmText: "Confirmar",
						confirmBillDetails: `¡Hola! Hemos escaneado su factura de servicios públicos para obtener el total, los costos de entrega y suministro, junto con el consumo de energía mensual. Nuestra herramienta hizo su mejor esfuerzo, pero a veces omite detalles. Por favor, revise los valores a continuación, complete cualquier información faltante o corrija errores. Los costos totales, de entrega y suministro son imprescindibles, y un poco sobre la energía mensual también ayuda. Su aporte garantiza resultados precisos. ¡Gracias por ayudarnos a perfeccionar sus datos energéticos!`,
					},
					BillUploader: {
						fileSizeError:
							"La imagen seleccionada excede el tamaño máximo permitido. Por favor, elija un archivo de imagen más pequeño.",
						fileSizeErrorPdf:
							"El archivo PDF subido excede el tamaño máximo permitido. Por favor, elija un archivo PDF más pequeño.",
						fileTypeError: "Por favor, suba un archivo PDF.",
						uploadBillText: "Por favor, suba su factura eléctrica",
						uploadBillSubText: "Arrastre y suelte o cargue el archivo PDF aquí",
						notSupportBillError:
							"El documento que cargó no parece ser una factura válida. Por favor, cargue una válida",
						skipUpload: "No tengo mi factura ahora y la proporcionaré más tarde",
					},

					ChatWidget: {
						emailLabel: "Correo electrónico",
						nameLabel: "Nombre",
						startChatText: "Iniciar chat",
					},

					YourSolar: {
						requestDesignText: "Solicitar diseño",
						successTextDesgin:
							"¡Tu solicitud ha sido enviada con éxito! Puede tardar hasta 7 días en completar tus diseños.",
					},

					Footer: {
						selectPlanText: "Ahora puede seleccionar un plan",
						viewProjectText: "Ver proyecto",
						skipPlanText: "Saltar a la selección de planes",
					},
				},
			},
		},
	});

export default i18n;
