import { Box, Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { AssuredWorkloadOutlined, Check, KeyboardArrowLeftOutlined, LinkOutlined } from "@mui/icons-material";
import CustomTextField from "../Common/CustomTextField";
import CustomButton from "../Common/CustomButton";
import { IconSquareBox } from "../Login/loginStyles";
import { FormEvent, useEffect, useMemo, useState } from "react";
import * as EmailValidator from "email-validator";
import MuiPhoneNumber from "material-ui-phone-number";
import GoogleMapsTextField, { PlaceType } from "../Common/GoogleMapsTextField";
import CustomRadioButtonsGroup from "../Common/CustomRadioButtonsGroup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import { storeFutureCustomerInformation } from "../../Services/futureCustomersService";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { resetUserState } from "../../Redux/Slices/userSlice";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useTranslation } from "react-i18next";

interface QualificationDialogProps {
	bill?: number | string;
	open: boolean;
	onClose?: () => void;
}

interface DataState {
	email?: string;
	name?: string;
	address?: string;
	phone?: string;
	addressObject?: PlaceType | null;
}

// const propertyTypes = {
// 	HOME: { value: "home", text: "Home" },
// 	BUSINESS: { value: "business", text: "Business" },
// 	NON_PROFIT: { value: "non-profit", text: "Non-Profit" },
// };

// const ownershipStatuses = {
// 	YES: { value: "yes", text: "Yes" },
// 	NO: { value: "no", text: "No" },
// };

const steps = {
	ONE: 1,
	TWO: 2,
	THREE: 3,
};

const defaultData = { email: "", name: "", phone: "", address: "", addressObject: null };

const QualificationDialog = ({ open, onClose, bill }: QualificationDialogProps) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const colors = useSelector(selectColors);

	// Memoize propertyTypes and ownershipStatuses to avoid recreating on every render
	const propertyTypes = useMemo(() => ({
		HOME: { value: "home", text: t("QualificationDialog.propertyTypeHome") },
		BUSINESS: { value: "business", text: t("QualificationDialog.propertyTypeBusiness") },
		NON_PROFIT: { value: "non-profit", text: t("QualificationDialog.propertyTypeNonProfit") },
	}), [t]);

	const ownershipStatuses = useMemo(() => ({
		YES: { value: "yes", text: t("QualificationDialog.ownershipYes") },
		NO: { value: "no", text: t("QualificationDialog.ownershipNo") },
	}), [t]);

	const [type, setType] = useState<string>(propertyTypes.HOME.value);
	const [isOwner, setIsOwner] = useState<string>(ownershipStatuses.YES.value);
	const [referralLink, setReferralLink] = useState<string>("");
	const [currentStep, setCurrentStep] = useState<number>(steps.ONE);
	const [data, setData] = useState<DataState>(defaultData);
	const [errors, setErrors] = useState<DataState>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [copied, setCopied] = useState<boolean>(false);

	const isStepOne = currentStep === steps.ONE;
	const isStepTwo = currentStep === steps.TWO;
	const isStepThree = currentStep === steps.THREE; // TODO: remove this step and display toast
	const isHomeOwner = type === propertyTypes.HOME.value;

	useEffect(() => {
		if (open) {
			setType(propertyTypes.HOME.value);
			setIsOwner(ownershipStatuses.YES.value);
			setCurrentStep(steps.ONE);
			setData(defaultData);
			setErrors(defaultData);
		}
	}, [open]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		setData((state: DataState) => ({ ...state, [name]: value }));
		setErrors((state: DataState) => ({ ...state, [name]: "" }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state: DataState) => ({ ...state, addressObject: value }));
		setErrors((state: DataState) => ({ ...state, address: "" }));
	};

	const validateData = () => {
		const allErrors = { ...errors };

		allErrors.name = data.name ? "" : t("QualificationDialog.nameError");
		allErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? t("Login.validEmailError")
				: ""
			: t("Login.emailError");
		allErrors.phone = data.phone ? "" : t("QualificationDialog.phoneError");
		allErrors.address = data.addressObject ? "" : t("QualificationDialog.addressError");

		setErrors(allErrors);
		console.log("allErrors", allErrors);
		return !Object.values(allErrors).find(Boolean);
	};

	const handleCheckPropertyDetails = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (isHomeOwner && isOwner === ownershipStatuses.YES.value) {
			navigate(allRoutes.SIGN_UP);
		} else {
			setCurrentStep(steps.TWO);
		}
	};

	const handleSubmitInformation = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!validateData()) return;
		setLoading(true);
		try {
			const payload = {
				name: data.name,
				email: data.email,
				phone_no: data.phone,
				address: data.addressObject?.description,
				bill: bill ?? 0,
				homeOwner: isHomeOwner,
				propertyType: Object.values(propertyTypes).find((item) => item.value === type)?.text || "",
			};

			const output = await storeFutureCustomerInformation(payload);
			console.log("out", output);

			if (output.data?.token) {
				setReferralLink(output.data?.token || "");
			}
			// setCurrentStep(steps.THREE);
			toast.success(t("VerifyEmailDialog.toastMsg"));
			navigate(allRoutes.ESTIMATE);
		} catch (error: any) {
			if (error.includes("is not a valid email address")) {
				setErrors({ ...errors, email: t("Login.validEmailError") });
			} else if (["User with this email already exists", "Record with this email already exists"].includes(error)) {
				setErrors({ ...errors, email: t("QualificationDialog.existingUserEmailError") });
			} else if (error === "Record with this phone number already exists") {
				setErrors({ ...errors, phone: t("QualificationDialog.existingUserPhoneError") });
			} else {
				toast.error(error);
			}
		}
		setLoading(false);
	};

	const handleCopyLink = () => {
		if (isHomeOwner) {
			// 		event.preventDefault();
			// event.stopPropagation();
			if (copied) return;

			if (navigator.clipboard) navigator.clipboard.writeText(referralLink);
			else copyToClipboard(referralLink);
			setCopied(true);
			setTimeout(() => setCopied(false), 2000);
		} else {
			dispatch(resetUserState());
			navigate(allRoutes.ESTIMATE);
		}
	};

	const copyToClipboard = async (text: string): Promise<boolean> => {
		let textarea: HTMLTextAreaElement | null = null;
		let result: boolean;

		try {
			textarea = document.createElement("textarea");
			textarea.setAttribute("readonly", "true");
			textarea.setAttribute("contenteditable", "true");
			textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
			textarea.value = text;

			document.body.appendChild(textarea);

			textarea.focus();
			textarea.select();

			const range = document.createRange();
			range.selectNodeContents(textarea);

			const sel = window.getSelection();
			if (sel) {
				sel.removeAllRanges();
				sel.addRange(range);
			}

			textarea.setSelectionRange(0, textarea.value.length);
			result = document.execCommand("copy");
		} catch (err) {
			console.error(err);
			result = false;
		} finally {
			if (textarea) {
				document.body.removeChild(textarea);
			}
		}

		if (!result) {
			alert("This action is not supported in this browser");
			return false;
		}
		return true;
	};

	const headingText = isStepTwo
		? isHomeOwner
			? t("QualificationDialog.unauthorizedPersonnel")
			: t("QualificationDialog.noInstantProposal")
		: isHomeOwner
			? t("QualificationDialog.unauthorizedPersonnel")
			: t("QualificationDialog.thankYouForDetails");

	return (
		<CustomDialog open={open} onClose={onClose}>
			{isStepOne && (
				<IconSquareBox
					sx={{
						border: `1px solid ${colors.border}`,
					}}
				>
					<AssuredWorkloadOutlined />
				</IconSquareBox>
			)}

			{(isStepTwo || isStepThree) && (
				<Typography variant="h5" textAlign="center">
					{headingText}
				</Typography>
			)}
			{/* <Typography fontSize={16} textAlign="center" mb={32} color={colors.textMid}>
				Our instant proposal is not available for your property type at this time. Please leave your contact details and
				a representative will follow up with you shortly
			</Typography> */}

			{isStepOne ? (
				<form onSubmit={handleCheckPropertyDetails}>
					<CustomRadioButtonsGroup
						top={24}
						label={t("QualificationDialog.propertyType")}
						value={type}
						options={Object.values(propertyTypes)}
						onChange={(value) => setType(value)}
					/>
					<CustomRadioButtonsGroup
						top={16}
						bottom={24}
						label={t("QualificationDialog.propertyOwner")}
						value={isOwner}
						options={Object.values(ownershipStatuses)}
						onChange={(value) => setIsOwner(value)}
					/>
					<CustomButton fullWidth disabled={loading} type="submit">
						{t("QualificationDialog.continueText")}
					</CustomButton>
				</form>
			) : isStepTwo ? (
				<form onSubmit={handleSubmitInformation}>
					<Box my={32} display="flex" flexDirection="column" gap={16}>
						<CustomTextField
							label="Name"
							onChange={handleOnChange}
							value={data.name}
							name="name"
							placeholder="Name"
							error={errors.name}
						/>

						<CustomTextField
							label="Email"
							onChange={handleOnChange}
							value={data.email}
							error={errors.email}
							name="email"
							type="email"
							placeholder="@example"
						/>

						<Box>
							<GoogleMapsTextField
								label="Solar Address"
								placeholder="Address"
								value={data.addressObject}
								onChange={handleAddress}
								error={errors.address}
							/>
						</Box>

						<Box>
							<Typography variant="h6" mb={10}>
								{t("QualificationDialog.phoneLabel")}
							</Typography>
							<MuiPhoneNumber
								defaultCountry={"us"}
								autoComplete="off"
								onChange={(phoneNumber: any) => {
									setData({ ...data, phone: phoneNumber.toString() });
									if (errors.phone) setErrors({ ...errors, phone: "" });
								}}
								fullWidth
								variant="outlined"
								size="small"
								value={data.phone}
								error={!!errors.phone}
								helperText={errors.phone}
								required
								InputLabelProps={{ shrink: true }}
							/>
						</Box>
					</Box>

					<CustomButton fullWidth disabled={loading} type="submit">
						{t("QualificationDialog.submitLabel")}
					</CustomButton>
				</form>
			) : isStepThree ? (
				<Box mt={32}>
					<CustomButton
						fullWidth
						variant="outlined"
						startIcon={isHomeOwner ? copied ? <Check /> : <LinkOutlined /> : <KeyboardArrowLeftOutlined />}
						onClick={handleCopyLink}
					>
						{isHomeOwner ? (copied ? t("QualificationDialog.linkCopied") : t("QualificationDialog.linkToForm")) : t("QualificationDialog.backToHomePage")}
					</CustomButton>
				</Box>
			) : (
				<></>
			)}
		</CustomDialog>
	);
};

export default QualificationDialog;
