import { createWorker } from "tesseract.js";

export const formatNumber = (value: string | number) => {
	const isDecimalValue = parseFloat((value || 0).toString()) !== parseInt((value || 0).toString());
	const numOfDecimals = isDecimalValue ? 2 : 0;

	return parseFloat(parseFloat((value || 0).toString()).toFixed(numOfDecimals)).toLocaleString();
};

export const decimalFreeNumber = (num: string | number): string | number => {
	return Number(Number(num).toFixed(0));
};
export const scanTextFromImage = async (image: any) => {
	const worker = await createWorker();
	const { data } = await worker.recognize(image);
	await worker.terminate();

	return data?.text || "";
};

export const getSatelliteImage = (address?: string) => {
	if (address) {
		return `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=20&size=800x800&maptype=satellite&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
	}
};

export const getStreetViewImage = (address?: string) => {
	if (address) {
		return `https://maps.googleapis.com/maps/api/streetview?size=800x800&location=${address}&fov=75&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
	}
};

export const validatePassword = (password: string | undefined) => {
	return password
		? password?.length < 8
			? "Password length must be at least 8 characters"
			: password?.search(/[A-Z]/) < 0
			? "Password requires at least one uppercase letter"
			: password?.search(/[a-z]/) < 0
			? "Password requires at least one lowercase letter"
			: password?.search(/[0-9]/) < 0
			? "Password requires at least one number"
			: ""
		: "Password cannot be empty";
};
