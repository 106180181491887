import { Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { EmailOutlined, KeyOutlined } from "@mui/icons-material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import CustomTextField from "../Common/CustomTextField";
import CustomButton from "../Common/CustomButton";
import { allRoutes } from "../../Routes/AllRoutes";
import { useNavigate } from "react-router-dom";
import { IconSquareBox } from "./loginStyles";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as EmailValidator from "email-validator";
import { sendResetPasswordLink } from "../../Services/passwordService";
import { useTranslation } from "react-i18next";

interface ForgotPasswordDialogProps {
	open: boolean;
	onClose?: () => void;
}

const ForgotPasswordDialog = ({ open, onClose }: ForgotPasswordDialogProps) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const colors = useSelector(selectColors);

	const [email, setEmail] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [emailSent, setEmailSent] = useState<boolean>(false);

	const isGmail = email.includes("@gmail.com");
	const isOutlook = email.includes("@live.com") || email.includes("@hotmail.com") || email.includes("@outlook.com");

	useEffect(() => {
		if (open) {
			setEmail("");
			setError("");
			setEmailSent(false);
		}
	}, [open]);

	const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setEmail(value);
		validateEmail(value);
	};

	const validateEmail = (currentValue = "") => {
		const updatedError = currentValue
			? !EmailValidator.validate(currentValue)
				? t("Login.validEmailError")
				: ""
			: t("Login.emailError");

		setError(updatedError);
		return !!updatedError;
	};

	const handleSendLink = async () => {
		if (validateEmail(email)) return;

		setLoading(true);
		try {
			const { data } = await sendResetPasswordLink(email);
			// TODO: inform Shahmeer to fix the api response

			if (data.resetlink === "user with given email doesn't exist") {
				toast.error(t("ForgotPasswordDialog.toastError"));
				setError(t("ForgotPasswordDialog.errorMsg"));
			} else {
				setEmailSent(true);
				toast.success(t("ForgotPasswordDialog.toastSuccess"));
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleOpenEmailApp = () => {
		let url = "";
		if (isGmail) url = "https://mail.google.com";
		else if (isOutlook) url = "https://outlook.com";
		window.open(url, "_blank");
	};

	const handleSignInClick = () => {
		if (emailSent) {
			handleSendLink();
		} else {
			navigate(allRoutes.LOGIN);
			onClose?.();
		}
	};

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox
				sx={{
					border: `1px solid ${colors.border}`,
				}}
			>
				{emailSent ? <EmailOutlined /> : <KeyOutlined />}
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				{emailSent ? t("ForgotPasswordDialog.checkYourEmail") : t("ForgotPasswordDialog.forgotPassword")}
			</Typography>
			<Typography fontSize={16} textAlign="center" mb={emailSent ? 6 : 32} color={colors.textMid}>
				{emailSent ? t("ForgotPasswordDialog.sentResetLink") : t("ForgotPasswordDialog.noWorries")}
			</Typography>
			{emailSent && (
				<Typography variant="body2" textAlign="center" mb={32}>
					{email || "test@sungroup.com"}
				</Typography>
			)}

			{!emailSent && (
				<CustomTextField
					autoFocus
					label="Email"
					type="email"
					bottom={24}
					value={email}
					onChange={handleChangeEmail}
					error={error}
				/>
			)}

			{emailSent ? (
				(isGmail || isOutlook) && (
					<CustomButton fullWidth disabled={loading} onClick={handleOpenEmailApp}>
						{t("ForgotPasswordDialog.openEmailText")}
					</CustomButton>
				)
			) : (
				<CustomButton fullWidth disabled={loading} onClick={handleSendLink}>
					{t("ForgotPasswordDialog.sendResetLinkText")}
				</CustomButton>
			)}
			<Typography fontSize={12} color={colors.textMid} textAlign="center" mt={32} mb={10}>
				{emailSent ? t("ForgotPasswordDialog.didntReceiveEmail") : t("ForgotPasswordDialog.rememberPassword")}
				<span className="link" onClick={handleSignInClick} style={{ fontWeight: 600 }}>
					{emailSent ? t("ForgotPasswordDialog.clickToResend") : t("ForgotPasswordDialog.signIn")}
				</span>
			</Typography>
		</CustomDialog>
	);
};

export default ForgotPasswordDialog;
