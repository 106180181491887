import { Box } from "@mui/material";
import CustomTabs from "../Common/CustomTabs";
import {
	AppRegistrationOutlined,
	CoPresentOutlined,
	DesignServicesOutlined,
	HandymanOutlined,
	ImageSearchOutlined,
	ThumbUpOutlined,
	TipsAndUpdatesOutlined,
} from "@mui/icons-material";
import SiteSurvey, { siteSurveySteps } from "./SubPages/SiteSurvey";
import { useEffect, useState } from "react";
import Activation from "./SubPages/Activation";
import { toast } from "react-toastify";
import CADDesign from "./SubPages/CADDesgin";
import Installation from "./SubPages/Installation";
import TownInspectionMeterReplacement from "./SubPages/TownInspectionMeterReplacement/TownInspectionMeterReplacement";
import PermitInterconnection from "./SubPages/PermitInterconnection/PermitInterconnection";
import ProposalAcceptance from "./SubPages/ProposalAcceptance";
import Navbar from "../Navbar/Navbar";
import Loader from "../Common/Loader";
import { getMyCurrentStep } from "../../Services/profileService";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { useSelector } from "../../Redux/reduxHooks";



const UpdateStatus = () => {

	const textSnippets = useSelector(selectAllTextSnippets);

	const allSteps = {
		PROPOSAL_ACCEPTANCE: { id: "proposal-accepted", text: textSnippets['tab_proposal_acceptance'] || "", icon: <ThumbUpOutlined /> },
		SITE_SURVEY: { id: "site-survey", text: textSnippets['tab_site_survey'] || "", icon: <CoPresentOutlined /> },
		CAD_DESIGN: { id: "cad-design", text: textSnippets['tab_cad_design'] || "", icon: <DesignServicesOutlined /> },
		PERMIT_APPLICATION: {
			id: "permit-application",
			text: textSnippets['tab_permit_interconnection'] || "Permit & Interconnection",
			icon: <AppRegistrationOutlined />,
		},
		INSTALLATION: { id: "installation", text: textSnippets['tab_installation'] || "", icon: <HandymanOutlined /> },
		TOWN_INSPECTION: {
			id: "town-inspection",
			text: textSnippets['tab_town_inspection'] || "",
			icon: <ImageSearchOutlined />,
		},
		ACTIVATION: { id: "activation", text: textSnippets['tab_activation'] || "", icon: <TipsAndUpdatesOutlined /> },
	};
	// const [enabledTabs, setEnabledTabs] = useState(Object.values(allSteps));
	const [tab, setTab] = useState(allSteps.PROPOSAL_ACCEPTANCE.id);
	const [loading, setLoading] = useState(false);
	const [enabledTabs, setEnabledTabs] = useState<any>(Object.values(allSteps));

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const { data: userData } = await getMyCurrentStep();

			const currentStep = userData?.currentStep
				? ["Permit Application", "Utility Interconnection"].includes(userData?.currentStep)
					? allSteps.PERMIT_APPLICATION.text
					: ["Town Inspection", "Meter Replacement"].includes(userData?.currentStep)
						? allSteps.TOWN_INSPECTION.text
						: userData?.currentStep === allSteps.SITE_SURVEY.text &&
							userData?.status === siteSurveySteps.TO_BE_SCHEDULE.text
							? allSteps.PROPOSAL_ACCEPTANCE.text
							: userData?.currentStep
				: allSteps.PROPOSAL_ACCEPTANCE.text;

			const step = Object.values(allSteps).find((item) => item.text === currentStep)?.id;
			setTab(step || allSteps.PROPOSAL_ACCEPTANCE.id);

			const indexOfActiveStep = Object.values(allSteps).findIndex((item) => item.id === step);
			const indexOfInstallation = Object.values(allSteps).findIndex(
				(item) => item.id === allSteps.PERMIT_APPLICATION.id,
			);
			let allEnabledTabs = [...enabledTabs];

			const enableInstallationTab =
				userData.currentStep === "Utility Interconnection" && userData.status === "Accepted";
			console.log("INSTAL", enableInstallationTab);

			allEnabledTabs = allEnabledTabs.map((item, idx) => ({
				...item,
				disabled:
					// ["Completed", "Accepted"].includes(userData.status) || !indexOfActiveStep
					indexOfActiveStep === 0 || (indexOfInstallation === indexOfActiveStep && enableInstallationTab)
						? idx > indexOfActiveStep + 1
						: idx > indexOfActiveStep,
			}));
			setEnabledTabs(allEnabledTabs);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleChangeTab = (newTab?: string) => {
		if (newTab) {
			setTab(newTab);
		}
	};


	const handleNextStep = (stepId: string) => {
		const indexOfActiveStep = Object.values(allSteps).findIndex((item) => item.id === stepId);

		if (indexOfActiveStep != -1) {
			const allEnabledTabs = [...enabledTabs];
			allEnabledTabs[indexOfActiveStep].disabled = false;
			setEnabledTabs(allEnabledTabs);
		}

		setTab(stepId);
	};

	return (
		<>
			<Navbar />
			<Loader open={loading} />
			<Box>
				<CustomTabs options={enabledTabs} value={tab} onChange={handleChangeTab} scrollingTabs={false} />
			</Box>

			<Box px={{ xs: 32, sm: 80 }}>
				{loading ? (
					<></>
				) : tab === allSteps.PROPOSAL_ACCEPTANCE.id ? (
					<ProposalAcceptance onContinue={() => handleNextStep(allSteps.SITE_SURVEY.id)} />
				) : tab === allSteps.SITE_SURVEY.id ? (
					<SiteSurvey onContinue={() => handleNextStep(allSteps.CAD_DESIGN.id)} />
				) : tab === allSteps.CAD_DESIGN.id ? (
					<CADDesign onContinue={() => handleNextStep(allSteps.PERMIT_APPLICATION.id)} />
				) : tab === allSteps.PERMIT_APPLICATION.id ? (
					<PermitInterconnection onContinue={() => handleNextStep(allSteps.INSTALLATION.id)} />
				) : tab === allSteps.INSTALLATION.id ? (
					<Installation onCancel={() => handleNextStep(allSteps.PERMIT_APPLICATION.id)} />
				) : tab === allSteps.TOWN_INSPECTION.id ? (
					<TownInspectionMeterReplacement onContinue={() => handleNextStep(allSteps.ACTIVATION.id)} />
				) : tab === allSteps.ACTIVATION.id ? (
					<Activation />
				) : (
					<></>
				)}
			</Box>
		</>
	);
};

export default UpdateStatus;
