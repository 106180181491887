import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import { Menu, PersonAddAlt1Outlined } from "@mui/icons-material";
import { StyledAppBar, StyledMenuBlock } from "./navbarStyles";
import { isAccessedByInstaller, isUserLoggedIn } from "../../Services/userService";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import CustomButton from "../Common/CustomButton";
import { useSelector } from "../../Redux/reduxHooks";
import { SGELogo } from "../../Utils/Images";
import ReferFriendDialog from "../Common/ReferFriendDialog";
import { useState } from "react";
import { selectColors, selectLogoUrl } from "../../Redux/Slices/generalSlice";
import Sidebar from "../Sidebar/Sidebar";
import { UserState, selectUser } from "../../Redux/Slices/userSlice";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";

const Navbar = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const userLoggedIn = isUserLoggedIn();
	const user: UserState = useSelector(selectUser);
	const isInstallerAccess = isAccessedByInstaller();
	const colors = useSelector(selectColors);

	const [dialog, setDialog] = useState(false);
	const [mobileOpen, setMobileOpen] = useState(false);

	const openDialog = () => setDialog(true);
	const closeDialog = () => setDialog(false);
	const handleLogoClick = () => navigate(allRoutes.ESTIMATE);
	const logoUrl: string = useSelector(selectLogoUrl);

	const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

	return (
		<>
			<StyledAppBar position="static" sx={{ color: colors.text, borderBottom: `1px solid ${colors.border}` }}>
				{
					!userLoggedIn ? (

						<div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
							<img
								src={logoUrl.length > 3 && logoUrl ? logoUrl : SGELogo}
								alt=""
								style={{ cursor: "pointer" }}
								onClick={handleLogoClick}
								className="main-logo"
							/>
							<LanguageSelector />
						</div>

					) : (
						<img
							src={logoUrl.length > 3 && logoUrl ? logoUrl : SGELogo}
							alt=""
							style={{ cursor: "pointer" }}
							onClick={handleLogoClick}
							className="main-logo"
						/>
					)
				}


				{userLoggedIn && !isInstallerAccess && (
					<>
						<StyledMenuBlock>
							<LanguageSelector />
							<CustomButton variant="outlined" color="primary" sx={{ padding: "6px 18px", gap: 8 }} onClick={openDialog}>
								<PersonAddAlt1Outlined sx={{ width: 20, height: 20 }} />
								<Typography color="inherit" display={{ xs: "none", sm: "inline-block" }}>
									{t("Navbar.referFriendText")}
								</Typography>
							</CustomButton>

							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 7 }}
							>
								<Menu />
							</IconButton>
						</StyledMenuBlock>
					</>
				)}
				{isInstallerAccess && user && (
					<Box display={"flex"} gap="10px" sx={{ "& button": { padding: "6px 10px", fontSize: "14px" } }}>
						<CustomButton
							variant="outlined"
							onClick={() => {
								const link = `${process.env.REACT_APP_INSTALLER_PANEL_URL || "https://sungroup-installer-portal.vercel.app"
									}/customers/update-status/${user.sequentialId}`;
								window.open(link, "_blank");
							}}
						>
							{t("Navbar.updateStatusText")}
						</CustomButton>
						<CustomButton
							variant="outlined"
							onClick={() => {
								const link = `${process.env.REACT_APP_INSTALLER_PANEL_URL || "https://sungroup-installer-portal.vercel.app"
									}/customers/logs/${user.sequentialId}`;
								window.open(link, "_blank");
							}}
						>
							{t("Navbar.viewLogsText")}
						</CustomButton>
						<CustomButton
							variant="outlined"
							onClick={() => {
								const link = `${process.env.REACT_APP_INSTALLER_PANEL_URL || "https://sungroup-installer-portal.vercel.app"
									}/customers/documents/${user.sequentialId}`;
								window.open(link, "_blank");
							}}
						>
							{t("Navbar.viewDocumentsText")}
						</CustomButton>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 7 }}
						>
							<Menu />
						</IconButton>
					</Box>
				)}
			</StyledAppBar>
			{userLoggedIn && !isInstallerAccess && <Sidebar open={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
			{isInstallerAccess && user && <Sidebar open={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
			<ReferFriendDialog open={dialog} onClose={closeDialog} />
		</>
	);
};

export default Navbar;
