import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { RoundedImage } from "./whyUsStyles";
import DayNightSolar from "./DayNightSolar";
import { borderRadius } from "../../Utils/spacings";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { HomeImage } from "../../Utils/Images";
import { formatNumber } from "../../Utils/utils";
import { useEffect, useState } from "react";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import CustomButton from "../Common/CustomButton";
import CustomDialog from "../Common/CustomDialog";
import { requestDetailDesign } from "../../Services/auroraDetailDesignService";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const ValueWithTitle = ({ title = "", value = "" }: { title?: string; value?: string }) => {
	return (
		<Box>
			<Typography variant="h5" fontSize={18}>
				{title}
			</Typography>
			<Typography fontSize={16} mt={12}>
				{value}
			</Typography>
		</Box>
	);
};

export const SolarSystemSpecs = ({ solarData, showDetailDesignBtn, auroraPanelsInfo, defaultSolarInfo }: { solarData: any; showDetailDesignBtn?: boolean; auroraPanelsInfo?: any; defaultSolarInfo?: any }) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation()
	const textSnippets = useSelector(selectAllTextSnippets);
	const [detailDesignRequest, setdetailDesignRequest] = useState<boolean>(false);
	const [loading, setIsLoading] = useState<boolean>(false);
	const options = [
		{
			title: textSnippets["system_size_label"] || "System Size",
			value: `${formatNumber(solarData?.SolarSize || 0)} kW`,
		},
		{
			title: textSnippets["system_production_label"] || "System Production",
			value: `${formatNumber(solarData?.OptimalSP || 0)} Khw`,
		},
		{
			title: textSnippets["electricity_from_solar"] || "% electricity from Solar",
			value: `${formatNumber(solarData?.SolarOffset)} %`,
		},
		{
			title: textSnippets["panels_labels"] || "Panels",
			value: !auroraPanelsInfo
				? `${formatNumber(solarData?.Panels || 0)} ${defaultSolarInfo?.panelName || "Hanwah Q-Cells 400"}`
				: `${formatNumber(solarData?.Panels || 0)} ${auroraPanelsInfo?.solarPanel}`,
		},
		{
			title: textSnippets["inverter_label"] || "Inverter",
			value: !auroraPanelsInfo
				? `${formatNumber(solarData?.Panels || 0)} ${defaultSolarInfo?.inverterName || "Enphase micro inverters"}`
				: `${formatNumber(solarData?.Panels || 0)} ${auroraPanelsInfo?.inverter}`

		},
		...(auroraPanelsInfo?.dc
			? [
				{
					title: "DC Optimizers",
					value: `${formatNumber(solarData?.Panels || 0)} ${auroraPanelsInfo?.dc}`,
				},
			]
			: []),

		{
			title: textSnippets["warranties_label"] || "Warranties",
			value:
				textSnippets["warranty_detail"] ||
				"25 year equipment maintenance and monitoring 10 year workmanship warranty (includes roof penetration)",
		},
	];
	const openDesignRequestDialog = () => setdetailDesignRequest(true);
	const closeDesignRequestDialog = () => setdetailDesignRequest(false);

	const requestAuroraDetailDesign = async () => {
		try {
			setIsLoading(true);
			const req = await requestDetailDesign();
			if (req) {
				openDesignRequestDialog();
			}
		} catch (error: any) {
			toast.error(error)
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box
			sx={{
				borderRadius: borderRadius.sm,
				backgroundColor: colors.primaryExtraLight,
				display: "flex",
				flexDirection: "column",
				gap: 24,
				padding: 24,
				pb: 42,
			}}
		>
			<Loader open={loading} />
			<Typography variant="h4">{textSnippets["solar_panel_specs"] || "Your Solar Panel System Specs "}</Typography>
			<Divider />
			{options.map((item, idx) => (
				<ValueWithTitle key={idx} title={item.title} value={item.value} />
			))}
			<Box >

				<CustomButton sx={{ display: showDetailDesignBtn ? 'block' : 'none' }} onClick={requestAuroraDetailDesign}>{t("YourSolar.requestDesignText")}</CustomButton>
				<CustomDialog open={detailDesignRequest} onClose={closeDesignRequestDialog}>
					<Typography>{t("YourSolar.successTextDesgin")}</Typography>
				</CustomDialog>
			</Box>
		</Box>
	);
};

const YourSolar = ({ id, data, satelliteImage, showDetailDesignBtn, auroraPanelsInfo, defaultSolarInfo }: { id?: string; data?: any; satelliteImage?: any; showDetailDesignBtn?: boolean; auroraPanelsInfo?: any; defaultSolarInfo: any }) => {
	const colors = useSelector(selectColors);

	const [solarData, setSolarData] = useState(data);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	useEffect(() => {
		setSolarData(data);
	}, [data]);
	const textSnippets = useSelector(selectAllTextSnippets);

	return (
		<Box id={id}>
			{isSmallScreen ? (
				<DayNightSolar />
			) : (
				<>
					<Typography variant={isSmallScreen ? "h3" : "h2"} mt={{ xs: 32, sm: 32 }} mb={10}>
						{textSnippets["switch_solar_save"] || "Switch to solar and save big"}
					</Typography>
					<Typography fontSize={16} mb={32}>
						{textSnippets[" delivery_charge_info"] ||
							"There is no delivery charge for electricity produced on your roof in MA. Switch to solar and save big."}
					</Typography>
					<Box
						sx={{
							display: "grid",
							gridTemplateColumns: { xs: "1fr", sm: "0.4fr 0.6fr" },
							gap: 26,
							alignItems: "stretch",
						}}
					>
						<SolarSystemSpecs solarData={solarData} showDetailDesignBtn={showDetailDesignBtn} auroraPanelsInfo={auroraPanelsInfo} defaultSolarInfo={defaultSolarInfo} />
						{!isSmallScreen && (
							<RoundedImage alt="" src={satelliteImage || HomeImage} style={{ borderRadius: borderRadius.sm }} />
						)}
					</Box>
					<DayNightSolar />
				</>
			)}
		</Box>
	);
};

export default YourSolar;
