import { Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { CheckOutlined } from "@mui/icons-material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import CustomButton from "../Common/CustomButton";
import { IconSquareBox } from "../Login/loginStyles";
import { useTranslation } from "react-i18next";

interface GoogleConfirmationDialogProps {
	open: boolean;
	onClose?: () => void;
}

const GoogleConfirmationDialog = ({ open, onClose }: GoogleConfirmationDialogProps) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox
				sx={{
					border: `1px solid ${colors.border}`,
				}}
			>
				<CheckOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				{t("GoogleConfirmationDialog.accountCreatedtext")}
			</Typography>
			<Typography fontSize={16} textAlign="center" mb={32} color={colors.textMid}>
				{t("GoogleConfirmationDialog.accountCreatedSubtext")}
			</Typography>

			<CustomButton fullWidth onClick={onClose} sx={{ mb: 10 }}>
				{t("VerifyEmailDialog.buttonTextClose")}
			</CustomButton>
		</CustomDialog>
	);
};

export default GoogleConfirmationDialog;
