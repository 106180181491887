import { Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { HandshakeOutlined } from "@mui/icons-material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import CustomTextField from "../Common/CustomTextField";
import CustomButton from "../Common/CustomButton";
import { IconSquareBox } from "../Login/loginStyles";
import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import { selectUser } from "../../Redux/Slices/userSlice";
import { updateProfile } from "../../Services/profileService";
import { useTranslation } from "react-i18next";

interface AcceptProposalDialogProps {
	open: boolean;
	onClose?: () => void;
	plan?: string;
	packagePrice: number | null;
	systemPrice: number | null;
	systemSize: number | null;
}

const AcceptProposalDialog = ({
	open,
	onClose,
	plan,
	packagePrice,
	systemPrice,
	systemSize,
}: AcceptProposalDialogProps) => {
	const colors = useSelector(selectColors);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const [name, setName] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (open) {
			setName("");
			setError("");
		}
	}, [open]);

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setName(value.trimStart());
		if (error) setError("");
	};

	const validateName = (currentValue = "") => {
		const updatedError = currentValue.trim()
			? currentValue.toLowerCase() !== user.name.toLowerCase()
				? t("AcceptProposalDialog.nameMismatchError")
				: ""
			: t("AcceptProposalDialog.nameError");

		setError(updatedError);
		return !!updatedError;
	};

	const handleSendLink = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (validateName(name)) return;

		setLoading(true);
		try {
			const formData = new FormData();

			formData.append("selectedPlan", plan ?? "");
			formData.append("packagePrice", JSON.stringify(packagePrice ?? 0));
			formData.append("systemPrice", JSON.stringify(systemPrice ?? 0));
			formData.append("systemSize", JSON.stringify(systemSize ?? 0));

			await dispatch(updateProfile(formData));

			navigate(allRoutes.SCHEDULING);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox
				sx={{
					border: `1px solid ${colors.border}`,
				}}
			>
				<HandshakeOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				{t("AcceptProposalDialog.acceptProposalText")}
			</Typography>
			<Typography fontSize={16} textAlign="center" mb={32} color={colors.textMid}>
				{t("AcceptProposalDialog.acceptProposalSubText")}
				<br />
				{t("AcceptProposalDialog.acceptProposalSubText2")}
			</Typography>

			<form onSubmit={handleSendLink}>
				<CustomTextField
					autoFocus
					placeholder={t("AcceptProposalDialog.namePlaceholder")}
					bottom={24}
					value={name}
					onChange={handleChangeName}
					error={error}
				/>

				<CustomButton type="submit" fullWidth disabled={loading} sx={{ mb: 10 }}>
					{t("AcceptProposalDialog.acceptProposalButtonText")}
				</CustomButton>
			</form>
		</CustomDialog>
	);
};

export default AcceptProposalDialog;
