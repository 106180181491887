import { Box, Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { PersonAddAlt1Outlined } from "@mui/icons-material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import CustomTextField from "../Common/CustomTextField";
import CustomButton from "../Common/CustomButton";
import { IconSquareBox } from "../Login/loginStyles";
import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as EmailValidator from "email-validator";
import { sendReferralLinkToFriend } from "../../Services/profileService";
import MuiPhoneNumber from "material-ui-phone-number";
import GoogleMapsTextField, { PlaceType } from "./GoogleMapsTextField";
import Asterisk from "./Asterisk";
import { useTranslation } from "react-i18next";

interface ReferFriendDialogProps {
	open: boolean;
	onClose?: () => void;
}

interface DataState {
	email?: string;
	name?: string;
	lastName?: string;
	address?: string;
	phone?: string;
	addressObject?: PlaceType | null;
}

const defaultData = { email: "", name: "", phone: "", address: "", addressObject: null };

const ReferFriendDialog = ({ open, onClose }: ReferFriendDialogProps) => {
	const { t } = useTranslation();
	const colors = useSelector(selectColors);

	const [data, setData] = useState<DataState>(defaultData);
	const [errors, setErrors] = useState<DataState>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (open) {
			setData(defaultData);
			setErrors(defaultData);
		}
	}, [open]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		setData((state: DataState) => ({ ...state, [name]: value }));
		setErrors((state: DataState) => ({ ...state, [name]: "" }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state: DataState) => ({ ...state, addressObject: value }));
		setErrors((state: DataState) => ({ ...state, address: "" }));
	};

	const validateData = () => {
		const allErrors = { ...errors };

		allErrors.name = data.name ? "" : t("Signup.firstNameError");
		allErrors.lastName = data.lastName ? "" : t("Signup.lastNameError");
		allErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? t("Login.validEmailError")
				: ""
			: t("Login.emailError");
		allErrors.phone = data.phone ? "" : t("QualificationDialog.phoneError");

		setErrors(allErrors);
		console.log("allErrors", allErrors);
		return !Object.values(allErrors).find(Boolean);
	};

	const handleSendLink = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!validateData()) return;

		setLoading(true);
		try {
			const payload = {
				name: data.name,
				lastName: data.lastName,
				email: data.email,
				phoneNo: data.phone,
				address: data.addressObject?.description || "",
			};
			await sendReferralLinkToFriend(payload);

			toast.success(t("ReferFriendDialog.referralLinkSuccessToast"));
			onClose?.();
		} catch (error: any) {
			if (error === "User with given email already have an account") {
				setErrors({ ...errors, email: t("ReferFriendDialog.emailAlreadyExistError") });
			}
			else toast.error(error);
		}
		setLoading(false);
	};

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox
				sx={{
					border: `1px solid ${colors.border}`,
				}}
			>
				<PersonAddAlt1Outlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				{t("Navbar.referFriendText")}
			</Typography>
			<Typography fontSize={16} textAlign="center" mb={32} color={colors.textMid}>
				{t("ReferFriendDialog.referFriendDescription")}
			</Typography>

			<form onSubmit={handleSendLink}>
				<CustomTextField
					autoFocus
					label={
						<>
							{t("Signup.firstNameLabel")}
							<Asterisk />
						</>
					}
					name="name"
					bottom={16}
					placeholder="Enter first name"
					value={data.name}
					onChange={handleOnChange}
					error={errors.name}
				/>
				<CustomTextField
					autoFocus
					label={
						<>
							{t("Signup.lastNameLabel")}
							<Asterisk />
						</>
					}
					name="lastName"
					bottom={16}
					placeholder="Enter last name"
					value={data.lastName}
					onChange={handleOnChange}
					error={errors.lastName}
				/>

				<CustomTextField
					autoFocus
					label={
						<>
							{t("Login.emailLabel")}
							<Asterisk />
						</>
					}
					type="email"
					name="email"
					bottom={16}
					placeholder="Enter email"
					value={data.email}
					onChange={handleOnChange}
					error={errors.email}
				/>

				<Box mb={16}>
					<Typography mb={10} variant="h6">
						{t("QualificationDialog.phoneLabel")}
						<Asterisk />
					</Typography>
					<MuiPhoneNumber
						defaultCountry={"us"}
						autoComplete="off"
						onChange={(phoneNumber: any) => setData({ ...data, phone: phoneNumber.toString() })}
						fullWidth
						variant="outlined"
						size="small"
						value={data.phone}
						required
						InputLabelProps={{ shrink: true }}
						error={!!errors.phone}
					/>
				</Box>

				<Box mb={24}>
					<Typography mb={10} variant="h6">
						{t("Estimate.homeAddress")}
						<Asterisk />
					</Typography>
					<GoogleMapsTextField
						placeholder="Address"
						value={data.addressObject}
						onChange={handleAddress}
						error={errors.address}
					/>
				</Box>

				<CustomButton fullWidth disabled={loading} type="submit">
					{t("ReferFriendDialog.referFriendLinkBtnText")}
				</CustomButton>
			</form>
		</CustomDialog>
	);
};

export default ReferFriendDialog;
