import http from "./httpService";

const apiEndpoint = "/chat-config-msg";

// Define a type for query parameters
type QueryParams = Record<string, string | number | boolean>;

const DefaultMsgService = {
	getDefaultMsg: (queryParams: QueryParams = {}) => {
		// Only add the query string if queryParams is not empty
		const queryString = Object.keys(queryParams).length
			? `?${new URLSearchParams(queryParams as Record<string, string>).toString()}`
			: "";
		return http.get(`${apiEndpoint}/${queryString}`);
	},
};

// =====|  APIs  |=====

export const getDefaultMsg = (queryParams?: QueryParams) => {
	return DefaultMsgService.getDefaultMsg(queryParams || {});
};
